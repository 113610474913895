import React, { useEffect } from 'react';
import { Carousel } from 'react-materialize';
import  CaruselItem from './CaruselItem';
import 'materialize-css';
import './MainCarusel.css';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions/actions';
import sendGAEvent from '../functions/sendGAEvent';

const MainCarusel = props => {  
  useEffect( () => {
    props.onCaruselLoading()
  }, [])

  const data =  JSON.parse(localStorage.getItem("data"));
  let hotelName = data.message.name;
    
  const gaCategoryHandler = (catName) => {
    let sessionID = localStorage.getItem("sessionID") 
      sendGAEvent('category clicked',{
        cat_name: catName,
        from: "carusel",
        page_path: "some test path name",
        user_session_id :sessionID
      })     
   }
  
    return (
      <>
       <Carousel
            carouselId="Carousel-2"
            options={{
              dist: -50,
              duration: 200,
              fullWidth: false,
              indicators: false,
              noWrap: false,
              numVisible: 5,
              onCycleTo: null,
              padding: 0,
              shift: 0
            }}
          >
          {props.categories.map( category => (
            <div key={'category' + category.category_id}>
              <Link to={`/results/${category.category_id}`} style={{ textDecoration: 'none', color: '#000' }}>
                <CaruselItem 
                  id={category.category_id}
                  image={category.category_image}
                  categoryName={category.category_name}
                  clicked={gaCategoryHandler.bind(this,category.category_name)}
                />
              </Link>
            </div>
          ))}
        </Carousel>
       <p className="hotel-name">{hotelName}</p>
      </>
     );
}

const mapStateToProps = state => {
  return {
    categories: state.categories.cat
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onCaruselLoading: () => dispatch(actionCreators.getCategoriesFromServer())
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(MainCarusel);
