import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import './SmallDisplayGrid.css';

const useStyles = makeStyles((theme) => ({
   root: {
     flexGrow: 1,
     marginTop: '1rem'
   },
   paper: {
    textAlign: 'center',
    marginRight: '1px',
    color: theme.palette.text.secondary,
    width: '85px',
    height: '65px',
    borderRadius: '25px',
    backgroundColor: '#777777'
   },
 }));

const SmallDisplayGrid = () => {
 const classes = useStyles();
   const arr = [1,2,3,4,5,6,7,8,9,10,11,12];

   return (
         <div className={classes.root}>
            <Grid container spacing={1}>
              {arr.map( el => {
                return <Grid item xs={3} style={{flexBasis:0, padding:"2px"}}>                  
                         <Paper className={classes.paper} />
                       </Grid>  
              })}
            </Grid>
         </div>
      );
   }
export default SmallDisplayGrid;