const convertBase64 = (objType,objName,objID,url) => {
 fetch(url) 
 .then(response => response.blob())
  .then( blob => {
    // Create a new FileReader innstance
    const reader = new FileReader;

    reader.onloadend = ( (objType1,objName1,objID1,url1) => {
      let innerURL = url1;
      return ((e) => 
      {
        let filename = objType1 + "_" + objName1 + "_" + innerURL.substring(innerURL.lastIndexOf('/')+1);
        // console.log(filename);

        // TODO:: handle name duplication 
       // 
        if(objType1 =='hotel_back_img')
        {
          localStorage.setItem(filename,reader.result);
            // go to hotel 
            // set value on objType1 attribute
        }
        else if(objType1 =='category_image')
        {
            // go to category by objID
            // set value on objType1 attribute
        }
        else if(objType1 =='logo' || objType1 =='image')
        {
          // foreach category 
           // go to place by objID
            // set value on objType1 attribute
        }
      });
    })(objType,objName,objID,url);

    reader.readAsDataURL(blob); // readAsDataURL(blob) take blob object and turn it to a format <img /> undestands 
   }
  ).catch((error) => {
    let filename = objType + "_" + objName + "_" + url.substring(url.lastIndexOf('/')+1);

        // TODO:: handle name duplication 
        localStorage.setItem(filename,url);
  });
} 

export default function localStorageManager(data) {
    // hotel keys isolate
    localStorage.setItem('hotelCoordinates', JSON.stringify(data.message.coordinates))
    localStorage.setItem('current_hotel_id', JSON.parse(data.message.hotel_id))

    console.log('data proccessing...' , data);
    
    
    let hotel_back_img = data.message.background_img; // Hotel background image
     convertBase64('hotel_back_img',data.message.name,data.message.hotel_id,hotel_back_img);
     data.message.background_img_key = 'hotel_back_img' + "_" + data.message.name + "_" + hotel_back_img.substring(hotel_back_img.lastIndexOf('/')+1);
    
     let categories = data.message.categories;
    categories.forEach( category =>    // categories imges loop
      {
         convertBase64('category_image',category.category_name,category.category_id,category.category_image);
        // category.category_image_key = 'category_image' + "_" + category.category_name + "_" + category.category_image.substring(category.category_image.lastIndexOf('/')+1);
        
        let newTags =[];
        category.tags.forEach( tag =>
           {
            newTags.push({name:tag,isActive:false});

          });
          category.ntags = newTags;
      
     
         let places = category.places;
         places.forEach( place =>     // places images loop
         {
           convertBase64('logo',place.name,place.place_id,place.logo);
        //   place.logo_key = 'logo' + "_" + place.name + "_" + place.logo.substring(place.logo.lastIndexOf('/')+1);
      
           convertBase64('image',place.name,place.place_id,place.image);
        //   place.image_key = 'image' + "_" + place.name + "_" + place.image.substring(place.image.lastIndexOf('/')+1);
      
         });
     
    });

    // handle images
     //convertBase64('https://nicanor.s3.eu-central-1.amazonaws.com/images/logos/taly.jpg');
    // convert to local object 
   
    //  NOTE :: Saving handled object to local storage
    localStorage.setItem("data", JSON.stringify(data));
  }