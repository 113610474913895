import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MainHeader from './MainHeader';
// import SettingsSideDrawer from './SettingsSideDrawer';
// import Backdrop from '../UIElements/Backdrop';
import ModalPersonal from '../UIElements/ModalPersonal';
import Input from '../UIElements/Input';
import './MainNavigation.css';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Menu from './Menu';
// import FullDate from './FullDate';
// import Hour from './Hour';
import WishList from './Personal/WishList';
import ExportWishList from './Personal/ExportWishList';
// import Weather from './Weather';
// import Settings from './Settings/Settings';
import WishlistIndicator from '../../../Results/WishlistIndicator';


const useStyles = makeStyles({
  slider: {
    width: 300,
  },
});

const MainNavigation = props => {
  // const classNamees = useStyles();
  // const history = useHistory(); 
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInput, setShowInput] = useState(false);
  // const [updateAvailable, setUpdateAvailable] = useState(true); 
  let location = useLocation();
  
  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const openModalHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  const openInputHandler = () => {
    setShowInput(true);
  };

  const closeInputHandler = () => {
    setShowInput(false);
  };

  // setInterval(() => {
  //   setUpdateAvailable(true)
  // }, 5000);
  // 86400000
  // const reloadPageHandler = () => {
  //   history.replace(`/`);
  //   window.location.reload();
  //  }

  let favoritesItemsArrayParse = JSON.parse(localStorage.getItem('favoritesArray'));
  let favoritesItemsArray = favoritesItemsArrayParse ? favoritesItemsArrayParse : []; 
  let heart_beat_wishlist = favoritesItemsArray.length !== 0 ? <WishlistIndicator sum={favoritesItemsArray.length} /> : null;
  
  return (
    <React.Fragment>
      <ModalPersonal 
        show={showModal} 
        onCancel={closeModalHandler}
        header='Personal'
        footer={<ExportWishList />} >
             <WishList />
      </ModalPersonal>
    
      <Input 
        show={showInput} 
        onCancel={closeInputHandler}>
      </Input>

      {/* {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SettingsSideDrawer show={drawerIsOpen} >
       <button onClick={reloadPageHandler} className={updateAvailable ? "update-btn" : "update-btn-hidden"}>Update available</button>
        <Settings />
      </SettingsSideDrawer> */}

      <MainHeader>
        <Grid container style={{height: "78px"}}>
          <Grid item xs={4} className="search-icon-wrapper" >
              <SearchIcon
                  id="search-icon"
                  style={{ fontSize: '50px', marginLeft:'25px' }}
                  onClick={openInputHandler} />
                 
          </Grid>
          <Grid item xs={4} className="logo" > 
              {/* <p className="hotel-name">{hotelName}</p> */}
              {location.pathname==="/" && heart_beat_wishlist}
          </Grid>
          <Grid item xs={2} className="weather-data">
            <div className="hour-weather-wropper">
               {/* <Weather /> */}
            </div>   
          </Grid>
          {/* <Grid item xs={2} className={updateAvailable ? "menu-with-alert" : "menu"} >       */}
          <Grid item xs={2} className="menu">  
              <Menu openSettingsDrawer={openDrawerHandler}
                    openPersonalModal={openModalHandler} />                     
          </Grid>
        </Grid>
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;