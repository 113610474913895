import React, {useState} from 'react';

// import Iframe from '../shared/components/UIElements/Iframe';
import './ChosenItem.css';
import CloseIcon from '@material-ui/icons/Close';
// import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import sendGAEvent from '../functions/sendGAEvent';
import no_image_found from '../images/no_image_found.png';
import red_hurt from '../images/red_hurt.png';
import wish_list_add from '../images/wish_list_add.png';
import WishlistIndicator from '../Results/WishlistIndicator';
import MatterportModal from './MatterportModal';

import Modal from 'react-modal';
import DemoCarousel from './DemoCarousel';

import Map from './Map';


var favoritesItemsArray = [];
var favoritesItemsIdArray = [];

const ChosenItem = props => {
  
  const [showImgsModal, setShowImgsModal] = useState(false);
  const [loaderIcon, setLoaderIcon] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  console.log("chosenItem renderd!");

  const data  = JSON.parse(localStorage.getItem("data"));
  const hotelPossition = data.message.address;

  const openImgsModalHandler = () => {
    setShowImgsModal(true);
  }

  const closeImgsModalHandler = () => {
      setShowImgsModal(false);
  }
  
  // const openImgsModalHandler = (placeName) => {
  //  let sessionID = localStorage.getItem("sessionID") 
  //   sendGAEvent('secondary Imgs viewed',{
  //     place_name: placeName,
  //     from: "chosen Item sidedrawer",
  //     page_path: "some test path name",
  //     user_session_id: sessionID
  //   }) 
  //   setShowImgsModal(true);
  // };

  // const closeImgsModalHandler = () => {
  //   setShowImgsModal(false);
  // };

  let favoritesItemsArrayParse = JSON.parse(localStorage.getItem('favoritesArray'));
  let favoritesItemsIdArrayParse = JSON.parse(localStorage.getItem('favoritesIdArray'));

  favoritesItemsArray = favoritesItemsArrayParse ? favoritesItemsArrayParse : []; 
  favoritesItemsIdArray = favoritesItemsIdArrayParse ? favoritesItemsIdArrayParse : [];

  let isItemFav = true;

  console.log(props.itemDataObject);
  let indexOfItem = favoritesItemsIdArray.indexOf(props.itemDataObject.place_id); 

  if (favoritesItemsArray.length === 0 || indexOfItem === -1) {
    isItemFav = false;
  }

  const insertItemToFavorites = (newFavItem, closeSideDrawer) => {
    // GA logic
   let sessionID = localStorage.getItem("sessionID") 
    sendGAEvent('add to wish list',{
      name: newFavItem.name,
      from: "chosen item",
      page_path: "some test path name",
      user_session_id: sessionID
    })   

    // save as session item in server

    console.log(sessionID);
    console.log( newFavItem.place_id);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,
                 'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
      body: JSON.stringify({ session_id: sessionID, place_id: newFavItem.place_id})
    };
    fetch('https://botpower.rendit.tech/api/atlas/InsertToSession', requestOptions)
    .then( response => response.json())
    .then( answer => {
      if (answer.message) {
          //save in local storage
          favoritesItemsArray.push(newFavItem); 
          favoritesItemsIdArray.push(newFavItem.place_id)
          let favoritesItemsArrayStringify = JSON.stringify(favoritesItemsArray)
          let favoritesItemsIdArrayStringify = JSON.stringify(favoritesItemsIdArray)
          localStorage.setItem('favoritesArray', favoritesItemsArrayStringify);
          localStorage.setItem('favoritesIdArray', favoritesItemsIdArrayStringify);
      }else{
          console.log('somthing went wronge...');
      }
    })

    setLoaderIcon(true);
    setTimeout(() => {
      setLoaderIcon(false);
      forceUpdate();
    }, 2500);
    // closeSideDrawer();
  }

  const RemoveItemToFavorites = (newFavItem, closeSideDrawer) => {
        // GA logic
      let sessionID = localStorage.getItem("sessionID") 
        sendGAEvent('remove from wish list',{
          name: newFavItem.name,
          from: "chosen item",
          page_path: "some test path name",
          user_session_id: sessionID
        })  
        // remove from session in the server
        console.log(sessionID);
        console.log(newFavItem.place_id);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,
                    'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
          body: JSON.stringify({ session_id: sessionID, place_id: newFavItem.place_id})
        };
        fetch('https://botpower.rendit.tech/api/atlas/RemoveFromSession', requestOptions)
        .then( response => response.json())
        .then( answer => {
          if (answer.message) {
                // remove from local storage (UI)
                  let favs = JSON.parse(localStorage.getItem("favoritesArray")) 
                  let favsId = JSON.parse(localStorage.getItem("favoritesIdArray")) 
                  favs = favs.filter( item => item.place_id !== newFavItem.place_id);
                  favsId = favsId.filter( item => item !== newFavItem.place_id);
                  localStorage.setItem("favoritesArray", JSON.stringify(favs)) 
                  localStorage.setItem("favoritesIdArray", JSON.stringify(favsId))
          }else{
               console.log('somthing went wrong...');
          }
        }).catch( err => console.log(err))
        setLoaderIcon(true);
        setTimeout(() => {
          setLoaderIcon(false);
          forceUpdate();
        }, 2500);
        // closeSideDrawer();
    }

    const signEventInGA = (place_name) => {
      if (props.itemDataObject.matterport_id !== null) {
       let sessionID = localStorage.getItem("sessionID")  
        sendGAEvent('3D viewed',{
          name: place_name,
          from: "chosen item",
          page_path: "some test path name",
          user_session_id: sessionID
        })  
      }
    }
 
    let mainImg = null;
    console.log(props.itemDataObject.matterport_id);
      if (props.itemDataObject.matterport_id !== null && props.itemDataObject.matterport_id !== "") {
           mainImg = <MatterportModal itemDataObject={props.itemDataObject}  />
        // mainImg = <img src={threeD} width="252px" height="176px" />
        // mainImg = <Iframe iframe={props.itemDataObject.matterport_id}  />
      }else if(props.itemDataObject.video !== null && props.itemDataObject.video !== "") {
        mainImg = <React.Fragment>
                      <video width="252px" height="176px" controls>
                          <source src={props.itemDataObject.video} type="video/mp4" />
                      </video>
                  </React.Fragment>
      }else if(props.itemDataObject.mainImg !== null && props.itemDataObject.mainImg !== ""){
        mainImg = <img src={props.itemDataObject.mainImg} alt="mainImg" width="252px" height="176px" />
      }else {
        mainImg = <img src={no_image_found} alt="not found" width="252px" height="176px" />
      }
 
  let heart_beat_wishlist = favoritesItemsArray.length !== 0 ? <WishlistIndicator sum={favoritesItemsArray.length} /> : null;    
  
  let from = hotelPossition;
  let to = props.itemDataObject.address;

      const crouselModalStyles = {
        content: {
          position: 'absolute',
          top: '80px',
          left: '220px',
          inset: `110px 50px 10px 200px`,
          float: 'center',
          padding: 0,
          marging: '0',
          width: '648px',
          height:'600px'
          // background: 'rgb(255,255,255,0)'
        },
      };

      console.log(showImgsModal);
  return (
    <React.Fragment> 
             {heart_beat_wishlist}
             {/* <AutoRotatingCarousel 
               landscape={true}
               open={showImgsModal} 
               interval={1000000}
               onClose={closeImgsModalHandler}>             
                <img src={props.itemDataObject.secImages[0]} alt={props.alt} width="648px" height="600px" />
                <img src={props.itemDataObject.secImages[1]} alt={props.alt} width="648px" height="600px" /> 
                <img src={props.itemDataObject.secImages[2]} alt={props.alt} width="648px" height="600px" />          
             </AutoRotatingCarousel> */}

              <Modal isOpen={showImgsModal} style={crouselModalStyles} onRequestClose={closeImgsModalHandler}>
                  <DemoCarousel itemDataObject={props.itemDataObject} />
              </Modal>

       <div className="iframe-item__wrapper">
       <div className="close-drawer-btn-wrapper">
          <button className="close-item-drawer-btn" onClick={props.closeSideDrawer}>
            <CloseIcon style={{fontSize: "2rem"}} />
          </button> 
        </div>
          <div onClick={signEventInGA.bind(this,props.itemDataObject.name)} className="iframe-item__content">
             {mainImg}
          </div> 
          <div className={props.itemDataObject.secImages.length===0 ? "imgs-wrapper-empty" : "imgs-wrapper"}>
            <img className="sec-img" onClick={openImgsModalHandler} src={props.itemDataObject.secImages[0]} alt={props.alt} width="95px" height="60px" /> 
            <img className="sec-img" onClick={openImgsModalHandler} src={props.itemDataObject.secImages[1]} alt={props.alt} width="95px" height="60px" />
            <img className="sec-img" onClick={openImgsModalHandler} src={props.itemDataObject.secImages[2]} alt={props.alt} width="95px" height="60px" />
          </div>
        </div>

          {loaderIcon && 
          <>
          <div className="loader-wrapper">
            <div className="loader">
              <div className="roller"></div>
              <div className="roller"></div>
            </div>
            <div className="loader loader-2">
              <div className="roller"></div>
              <div className="roller"></div>
            </div>
            <div className="loader loader-3">
              <div className="roller"></div>
              <div className="roller"></div>
            </div>
          </div>
          </>}                   

        <div className="info-container">
              {/* { isItemFav ?
               <img className="hurt-btn" src={wish_list_add} alt="add" width="45px" height="40px" onClick={RemoveItemToFavorites.bind(this,props.itemDataObject, props.closeSideDrawer)} /> :
               <img className="hurt-btn" src={wish_list_remove} alt="remove" width="45px" height="40px" onClick={insertItemToFavorites.bind(this,props.itemDataObject, props.closeSideDrawer)} />          
              }   */}
              <div className="item-hash-list">
                  {props.itemDataObject.tags.map(tag => {
                    return <p className="item-hash"  key={"@@" + tag}>#{tag}</p>
                  })}
              </div> 
              <div className="place-info">

                 { isItemFav ?
                  <img className="hurt-btn" src={red_hurt} alt="add" width="45px" height="40px" onClick={RemoveItemToFavorites.bind(this,props.itemDataObject, props.closeSideDrawer)} /> :
                  <img className="hurt-btn" src={wish_list_add} alt="remove" width="45px" height="40px" onClick={insertItemToFavorites.bind(this,props.itemDataObject, props.closeSideDrawer)} />          
                  } 


                {/* <div className="titles-wrapper"> */}
                  <p className="item-info-title">{props.itemDataObject.name}</p>
                
                {/* </div> */}
                <p className="item-info-sub-title">{props.itemDataObject.sub_title}</p>
               <p className="item-info-discription">{props.itemDataObject.description}</p>
              </div>

              <div className="map-container" >
                <Map from={from} to={to} />
              </div>            
             {/* <ChosenItemInfo itemDataObject={props.itemDataObject} /> */}
        </div>  
     </React.Fragment>
    );
} 

export default ChosenItem;

