import React from 'react';
import { useSelector } from 'react-redux';

import './TagItem.css';

const TagItem = props => {
  let currentSliderVal = useSelector( state => state.slider.sliderValue);

  let singelTag = <li className="tag-item" onClick={props.clicked}>
                   <p className={props.isActive ? "tag-info-active" : "tag-info"}>{props.name}</p>
                  </li>

  if(currentSliderVal===3)
  {
   singelTag = <li className="tag-item-small-mode" onClick={props.clicked}>
    <p className={props.isActive ? "tag-info-active-small-mode" : "tag-info-small-mode"}>{props.name}</p>
   </li>
  }

   return singelTag
}
export default TagItem;