import React, {useState} from 'react';

import WishListItem from './WishListItem';
import WishListItemModal from '../../UIElements/WishListItemModal';
import Iframe from '../../UIElements/Iframe';
// import AddToWishBtn from './AddToWishBtn';
import Grid from '@material-ui/core/Grid';
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
// import BookNaw from '../../../../Results/BookNaw';
import no_image_found from '../../../../images/no_image_found.png';
import wish_list_add from '../../../../images/wish_list_add.png';
import sendGAEvent from '../../../../functions/sendGAEvent';
import './WishList.css';

const WishList = props => {

  const [showImgsModal, setShowImgsModal] = useState(false);
  const [showTinyModal, setShowTinyModal] = useState(false);
  const [tinyModalContant, setTinyModalContant] = useState(
    {
    id: 11,
    display: true,
    tags: ["kosher","Disabled_friendly"],
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQnXPwjOnE_hqRxUSUeKE0flBrApk1Au1w58Q&usqp=CAU",
    secImages : [
      "https://pizzangel.co.il/wp-content/uploads/2020/07/bg_0009_37jpg-1030x579.jpg",
      "https://pizzangel.co.il/wp-content/uploads/2020/07/bg_0009_37jpg-1030x579.jpg",
      "https://pizzangel.co.il/wp-content/uploads/2020/07/bg_0009_37jpg-1030x579.jpg"
      ],
    name: "INITIAL STATE",
    coordinates: { 
      latitude: 51.5103, 
      longitude: 7.49347 
            },
    distance: "",
    shifts: [
      {
        morning : {
                    start: "08:00",
                    end : "13:00"
                    }, 
        noon : {
                    start: "14:00",
                    end : "18:00"
                    }, 
        night : {
                    start : "20:00",
                    end : "23:59"
                    } 
        },
        {
          morning : {
                      start: "08:00",
                      end : "13:00"
                      }, 
          noon : {
                      start: "14:00",
                      end : "18:00"
                      }, 
          night : {
                      start : "20:00",
                      end : "23:59"
                      } 
          },
          {
            morning : {
                        start: "08:00",
                        end : "13:00"
                        }, 
            noon : {
                        start: "14:00",
                        end : "18:00"
                        }, 
            night : {
                        start : "20:00",
                        end : "23:59"
                        } 
            },
            {
              morning : {
                          start: "10:00",
                          end : "23:00"
                          }, 
              noon : {
                          start: "14:00",
                          end : "18:00"
                          }, 
              night : {
                          start : "20:00",
                          end : "23:59"
                          } 
              },
              {
                morning : {
                            start: "08:00",
                            end : "13:00"
                            }, 
                noon : {
                            start: "14:00",
                            end : "18:00"
                            }, 
                night : {
                            start : "20:00",
                            end : "23:59"
                            } 
                },
                {
                  morning : {
                              start: "08:00",
                              end : "13:00"
                              }, 
                  noon : {
                              start: "14:00",
                              end : "18:00"
                              }, 
                  night : {
                              start : "20:00",
                              end : "23:59"
                              } 
                  },
                  {
                    morning : {
                                start: "08:00",
                                end : "13:00"
                                }, 
                    noon : {
                                start: "14:00",
                                end : "18:00"
                                }, 
                    night : {
                                start : "20:00",
                                end : "23:59"
                  } 
        },
      ],
    info: { 
      "website": "https//someplace.io",
      "activity": "08:00 - 22:00",
      "phon": "052-5555-555",
      "adress": "30 disingof, Tel-Aviv"
    },
    status : "close",
    iframe: "https://my.360spaces.co.il/show/?m=atlas_tal_hotel"            
    }                                                                                                                                                           
    );

  let favs = JSON.parse(localStorage.getItem("favoritesArray")) 
  let favsId = JSON.parse(localStorage.getItem("favoritesIdArray")) 
  const [localFavsIdArray,setLocalFavsIdArray] = useState(favsId)

  const removeItemFromWishList = (place) => { 
    // GA logic
    sendGAEvent('remove from wish list',{
      name: place.name,
      from: "wish list",
      page_path: "some test path name"
    }) 
    // remove from session in the server
    let sessionID = localStorage.getItem("sessionID") 
    console.log(sessionID);
    console.log(place.place_id);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,
                'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
      body: JSON.stringify({ session_id: sessionID, place_id: place.place_id})
    };
    fetch('https://botpower.rendit.tech/api/atlas/RemoveFromSession', requestOptions)
    .then( response => response.json())
    .then( answer => {
      if (answer.message) {
            // remove from local storage (UI)
              let favs = JSON.parse(localStorage.getItem("favoritesArray")) 
              let favsId = JSON.parse(localStorage.getItem("favoritesIdArray")) 
              favs = favs.filter( item => item.place_id !== place.place_id);
              favsId = favsId.filter( item => item !== place.place_id);
              localStorage.setItem("favoritesArray", JSON.stringify(favs)) 
              localStorage.setItem("favoritesIdArray", JSON.stringify(favsId))
              setLocalFavsIdArray(favsId);
              showTinyModal && setShowTinyModal(false)   
      }else{
           console.log('somthing went wrong...');
      }
    }).catch( err => console.log(err))
   }

  const openModalOfWishListItem = result => {
    setShowTinyModal(true)
    setTinyModalContant(result)
  }

  const closeModalOfWishListItem = () => {
    setShowTinyModal(false);
  };

  const openImgsModalHandler = () => {
    setShowImgsModal(true);
  };

  const closeImgsModalHandler = () => {
    setShowImgsModal(false);
  };

  if (favs && favs.length !== 0) {
    // console.log(tinyModalContant);
      let mainImg = null;
      console.log(tinyModalContant.matterport_id);
      console.log(tinyModalContant.video);
      console.log(tinyModalContant.mainImg);
      if (tinyModalContant.matterport_id !== "" && tinyModalContant.matterport_id!==null) {
        mainImg = <Iframe iframe={tinyModalContant.matterport_id} style={{ width: "400px", height: "250px"}} />
      }else if(tinyModalContant.video !== "" && tinyModalContant.video!==null ) {
        mainImg = <React.Fragment>
                      <video width="400px" height="250px" controls>
                          <source src={tinyModalContant.video} type="video/mp4" />
                      </video>
                  </React.Fragment>
      }else if(tinyModalContant.mainImg !== "" && tinyModalContant.mainImg!==null ){
        mainImg = <img src={tinyModalContant.mainImg} alt="mainImg" width="400px" height="250px" />
      }else {
        mainImg = <img src={no_image_found} alt="not found" width="400px" height="250px" />
      }

    return (
      <React.Fragment>
            <AutoRotatingCarousel 
               open={showImgsModal} 
               interval={2000}
               onClose={closeImgsModalHandler}>             
                <img src={tinyModalContant.secImages[0]} alt="pic1" width="648px" height="600px" />
                <img  src={tinyModalContant.secImages[1]} alt="pic2" width="648px" height="600px" /> 
                <img src={tinyModalContant.secImages[2]} alt="pic3" width="648px" height="600px" />          
             </AutoRotatingCarousel>

          {/* <WishListItemModal 
            show={showTinyModal} 
            onCancel={closeModalOfWishListItem}>
            
            <button className="retire-from-wl-btn" onClick={removeItemFromWishList.bind(this,tinyModalContant)}>
              Retire from wish list</button>

             <Grid container spacing={3} style={{marginTop:"2rem"}}>
              <Grid item xs={6} >
                <div className="iframe-item-content">
                    {mainImg}
                </div>
                <div className={tinyModalContant.secImages.length===0 ? "tiny-modal-imgs-wrapper-empty" : "tiny-modal-imgs-wrapper"}>
                  <img onClick={openImgsModalHandler} src={tinyModalContant.secImages[0]} alt="pic1" width="124px" height="91px" /> 
                  <img onClick={openImgsModalHandler} src={tinyModalContant.secImages[1]} alt="pic2" width="124px" height="91px" />
                  <img onClick={openImgsModalHandler} src={tinyModalContant.secImages[2]} alt="pic3" width="124px" height="91px" />
                </div>
              </Grid>
              <Grid item xs={6} >
                 <div className="tiny-modal-titles-wrapper">
                   <h5 className="tiny-modal-title">{tinyModalContant.name}</h5>
                   <h5>{tinyModalContant.description}</h5>
                 </div>
                 <div className="tiny-modal-info-wrapper">
                   {tinyModalContant.info.activity && <h6>{`Opening houres: ${tinyModalContant.info.activity}`}</h6>}
                   {tinyModalContant.info.phon && <h6>{`Call Now: ${tinyModalContant.info.phon}`}</h6>}
                   {tinyModalContant.info.adress && <h6>{`Address:  ${tinyModalContant.info.adress}`}</h6>}
                 </div>
              </Grid>
              <button className="back-btn-tiny-modal"  onClick={closeModalOfWishListItem}>{"< Back"}</button>
            </Grid>
          </WishListItemModal> */}

          <ul className="wish-list-normal">            
           {/* <Grid container spacing={6} className="wish-list-normal"  >                   */}
            { favs.map( result => (
                <Grid key={result.place_id} item xs={4} className="wish-list-item">         
                  <WishListItem 
                    id={result.id}
                    image={result.image}
                    name={result.name}
                    sub_title={result.sub_title}
                    distance={result.distance}
                    status={result.status}
                    remove={removeItemFromWishList.bind(this,result)}
                    clicked={openModalOfWishListItem.bind(this,result)}
                    />                       
                </Grid>
              ))}
           {/* </Grid> */}
          </ul>
      </React.Fragment>
    )
  }

  return <div className="wishList-wrapper">  
            No entries yet, feel free to add them via the <img style={{padding: "5px"}} src={wish_list_add} alt="not found" width="45px" height="45px" /> button.
          </div>  
}

export default WishList;