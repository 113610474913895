import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import BigDisplayGrid from './BigDisplayGrid';
import SmallDisplayGrid from './SmallDisplayGrid';
import PasswordModal from './Admin/PasswordModal';
import sendGAEvent from '../../../../functions/sendGAEvent';

import './Settings.css';

const Settings = props => {
  // Big => true , Small => false
  const [sizeState, setSizeState] = useState(props.sliderVal === 4 ? true : false);

  useEffect( () => {
    if (sizeState === false) {
      props.onSliderChange(3);
    }
    if (sizeState === true) {
      props.onSliderChange(4);
    }
  },[sizeState])
 
  const sliderChangeHandler = () => { 
    if (sizeState) {
      sendGAEvent('size changed to small',{
        from: "settings",
        page_path: "some test path name"
      })     
    }
    setSizeState(prevstate => !prevstate)
  };
 
  return (
    <React.Fragment>
        <h1 className="sett-title">Settings</h1> 
             <Grid container spacing={3} style={{width: '100%', margin: '0'}}  className="size-sett-container">
                <Grid item xs={2} style={{paddingLeft: '0.5rem'}}>
                  <h3 style={{fontSize: '2rem', margin: '0'}}>Text Size</h3>
                  <label className="switch">
                    <input type="checkbox" onClick={sliderChangeHandler} defaultChecked={!sizeState} />
                    <span className="slider round"></span>
                  </label>     
                </Grid>
                <Grid item xs={5} style={{paddingRight: '0'}}>
                  <h2 className="exemple-text">Exemple text :</h2>
                  <p className={sizeState ? "big-text-active" : "big-text"}>Thank you for using DMTM technologies</p>
                  <p className={sizeState ? "small-text" : "small-text-active"}>Thank you for using<br/> DMTM technologies</p>
                </Grid>
                <Grid item xs={5} style={{padding: '0'}}>
                  <h2 className="exemple-text">Exemple Layout :</h2>
                  { sizeState ? <BigDisplayGrid /> : <SmallDisplayGrid />}                
                </Grid>
              </Grid>    

         {/* <div className="more-sett-container">
          <label className="switch-hor">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
          <label className="switch-hor">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>  */}

         <div className="hotel-sett-container">
            <h2 className="admin-only">Configure hotel ID (admin only)</h2> 
            <span className="current-hotel-name">Market House</span><PasswordModal />           
        </div>  
   </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    sliderVal: state.slider.sliderValue  
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSliderChange: (newValue) => dispatch({type: 'SLIDER_CHANGE' , value: newValue })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
