import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import './Map.css';

const Map = props => {
  return (
    <iframe style={{width: '357.69px'}} src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyBCRCHlJsP7etYQcUXUSHQDS7qgNaHBqvM&origin=${props.from}&destination=${props.to}&avoid=tolls|highways&mode=walking&language=en`} />
  )
}
 

export default Map;