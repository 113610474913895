import React, { useState } from 'react';
import { Link , Redirect, useHistory } from 'react-router-dom';
import ItemSideDrawer from '../../../Results/ItemSideDrawer';
import ChosenItem from '../../../Results/ChosenItem';
import Tour from 'reactour';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import sessionManager from '../../../functions/sessionManager';
import './TourComponent.css';

const TourComponent = () => {

  const [itemDrawerIsOpen, setItemDrawerIsOpen] = useState(false);
  const MySwal = withReactContent(Swal)

  let data = JSON.parse(localStorage.getItem("data")) 
  let exempelItem = data.message.categories[0].places[0];

  const openItemDreawer = () => {
    setItemDrawerIsOpen(true);
  };

  const closeItemDrawerHandler = () => setItemDrawerIsOpen(false);

  const goToNextPage = () => {
    return (<Redirect to='/results/1' />)
  }

  const goToPrevPage = () => {
    closeItemDrawerHandler();
    return (<Redirect to='/' />)
  }
  
  const [isTourOpen, setIsTourOpen] = useState(true);
  const history = useHistory();

  const startNewSessionAfterTour = () => {
    itemDrawerIsOpen && closeItemDrawerHandler();
    // remove old session data from local storage (UI)
    let favs = JSON.parse(localStorage.getItem("favoritesArray")) 
    let favsId = JSON.parse(localStorage.getItem("favoritesIdArray")) 
    favs = []
    favsId = []
    localStorage.setItem("favoritesArray", JSON.stringify(favs)) 
    localStorage.setItem("favoritesIdArray", JSON.stringify(favsId))

    let hotel_id = localStorage.getItem("current_hotel_id");
    sessionManager(hotel_id);
  
    MySwal.fire({
      didOpen: () => {
        MySwal.clickConfirm()
      }
    }).then(() => {
      return MySwal.fire(<p>Let's go!</p>)
    })
    history.replace("/");
  }

  const steps = [
    {
      content: 'WELCOME TO OCTOPUS! Now you can plane your trip in Tel Aviv in fun and eazy way! so lets get started',
      position: [344,150],
      style: {
        backgroundColor: '#ffffff',
      },
      stepInteraction: false,
    },
    {
      selector: '#menu-img',
      content: 'This is the menu button, You can "start a new trip planning" here, go to "your wishlist" and more.',
      style: {
        backgroundColor: '#ffffff',
      },
    },
    {
      selector: '#search-icon',
      content: () => (
       <div>
         <p className="tour-text">'Search here for places by there name or properties such "meat" "italian" "kosher" etc.'</p>
         {goToNextPage()}
      </div>
     ),                      
      style: {
        backgroundColor: '#ffffff',
     },
    },
    {
      selector: '.tag-item',
      content: 'You can filter the results using those buttons :).',
      style: {
        backgroundColor: '#ffffff',
     }
    },
    {
      selector: '.link-logo',
      content: 'You can go back to homepage from anywhere during the planning by clicking the "BACK" button.',
      style: {
        backgroundColor: '#ffffff',
     }
    },
    {
      selector: '.result-item',
      content: () => (
        <div>
          <p className="tour-text">'Click on the result for mor information about the place'</p>
          {openItemDreawer()}
       </div>
      ),  
      style: {
        backgroundColor: '#ffffff',
     }
    },
    {
      selector: '.item-side-drawer',
      content: 'Explor your choise with picturs, place description, map and EVEN HIGH  QUALITY 3D TURE!',
      style: {
        backgroundColor: '#ffffff',
     }
    },
    {
      selector: '.hurt-btn',
      content: 'Were you impressed? want to check it out? just click the hurt button and add it to your wishlist',
      style: {
        backgroundColor: '#ffffff',
     }
    },
    {
      selector: '#menu-img',
      content: () => (
       <div>
         <p className="tour-text">'In the end of your planning just click the menu and go to "My Wishlist" to pass the places to your phone using QR code.'</p>
         {goToPrevPage()}
      </div>
     ),                      
      style: {
        backgroundColor: '#ffffff',
     },
    }

  ]

  return (
    <>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        onBeforeClose={startNewSessionAfterTour}
        disableInteraction={true}
        lastStepNextButton={<><button className="end-of-tour-btn" onClick={startNewSessionAfterTour}>Got It!</button></>}
     />
      <ItemSideDrawer show={itemDrawerIsOpen}>
      {<ChosenItem itemDataObject={exempelItem} />}   
     </ItemSideDrawer>
    </>
   )
}

  export default TourComponent;