import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '../UIElements/Backdrop';
import terms_icon from '../../../images/terms_icon.png';


import './TermsModal.css';          


      const useStyles = makeStyles((theme) => ({
        paper: {
          position: 'absolute',
          outline: 'none',
          top: 100,
          left: 145,
          height: '70vh',
          width: '70vw',
          borderRadius: '1rem',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
      }));
      
const TermsModal = () => {
        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const [showTermsModal, setShowTermsModal] = React.useState(false);
              
        const handleOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {      
          setOpen(false);
        };

        const closeTermsModalHandler = () => setShowTermsModal(false);

      
        const body = (
          <div className={classes.paper}>
            text.....
          </div>
        );
      
        return (
          <React.Fragment>
          {showTermsModal && <Backdrop onClick={closeTermsModalHandler} />}
            <img onClick={handleOpen} src={terms_icon} width="42px" height="42px" alt="New"/>
            <p onClick={handleOpen} className="menu-li-text">Terms Of Use</p> 
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    {body}
                </Modal>
           </React.Fragment>
        );
      }

export default TermsModal;


