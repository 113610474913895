import React from 'react';

 import Avatar from '../shared/components/UIElements/Avatar';
import Card from '../shared/components/UIElements/Card';
import './CaruselItem.css';
import './shine-text.css';

const images = require.context('../images/carousel_imgs', true);

const CaruselItem = props => {

  
  

   return (
     <Card className="carusel-item__content">
        <div onClick = {props.clicked} className="carusel-item__image">
            {/* <img src={images(props.image)} alt="img" width="100%" height="100%" /> */}
            
            <Avatar image={props.image} alt={props.alt} />
        </div> 
        <div className="carusel-item__info">
            <p className="shine-text">{props.categoryName}</p>
        </div>
     </Card>
   )
}
export default CaruselItem;
