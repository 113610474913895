import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Iframe from '../shared/components/UIElements/Iframe';
import threeD from '../images/threeD.gif';

import './MatterportModal.css';          


      const useStyles = makeStyles((theme) => ({
        paper: {
          position: 'absolute',
          outline: 'none',
          bottom: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
        //   padding: theme.spacing(2, 4, 3),
        },
      }));
      
const MatterportModal = props => {
        const classes = useStyles();
        const [open, setOpen] = useState(false);
        const [showMatterportModal, setShowMatterportModal] = useState(false);
              
        const handleOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {      
          setOpen(false);
        };

        const closeMatterportModalHandler = () => setShowMatterportModal(false);

      
        const body = (
          <div className={classes.paper}>
             <div className="modal-3d-header">
              <button className="close-3d-modal-btn" onClick={handleClose} >X</button>
              <p className="modal-3d-header-phone">{props.itemDataObject.phone_number}</p>
              <p className="modal-3d-header-address">{props.itemDataObject.address}</p>
            </div>         
            <Iframe iframe={`${props.itemDataObject.matterport_id}&nt=1&play=1`}  />
          </div>
        );
      
        return (
          <React.Fragment>
            <img onClick={handleOpen} className="play-gif" src={threeD} width="300px" height="170px"   />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    {body}
                </Modal>
           </React.Fragment>
        );
      }

export default MatterportModal;


