import React, { useState }  from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '../../UIElements/Backdrop';
import Settings from '../Settings/Settings';
import settings_icon from '../../../../images/settings_icon.png';
import SettingsSideDrawer from '../../Navigation/SettingsSideDrawer';


import './PasswordModalForSettings.css';          


      const useStyles = makeStyles((theme) => ({
        paper: {
          position: 'absolute',
          outline: 'none',
          top: 300,
          left: 145,
          height: '20vh',
          width: '70vw',
          borderRadius: '1rem',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
      }));
      
const PasswordModalForSettings = () => {
        const history = useHistory(); 
        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const [inputValue, setInputValue] = React.useState('');
        const [showSettingsModal, setShowSettingsModal] = React.useState(false);
        const [updateAvailable, setUpdateAvailable] = useState(false); 
        const [msg, setMsg] = React.useState();
        
      
        const handleOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setMsg(null)
          setInputValue('');
          setOpen(false);
        };

        const handleInput = event => {
          setInputValue(event.target.value);
        }

        const handleSubmit = event => {
          event.preventDefault();
          if (inputValue === '12345') {
            console.log('loading Settings..');
            setInputValue('');
            setMsg(null)
            handleClose();
            setShowSettingsModal(true)
          }else{
            console.log('wrong password...');
            setInputValue('');
            setMsg('wrong password...')
          }
        }

        const closeSettingsModalHandler = () => setShowSettingsModal(false);

        const reloadPageHandler = () => {
            history.replace(`/`);
            window.location.reload();
           }

        setInterval(() => {
          setUpdateAvailable(true)
        }, 5000);
      
        const body = (
          <div className={classes.paper}>
            <h2 id="simple-modal-title">Administrator area</h2>
            <p id="simple-modal-description">You need to put password to perform this action.</p>
            <form className="form-container" onSubmit={handleSubmit}>
              <input 
              autoFocus 
              className="input-password"
              type="password" 
              value={inputValue}
              onChange={handleInput} /> 
              <button className="submit-btn">Enter</button>
            </form>  
             {msg && <p className="wrong-password">{msg}</p>}   
          </div>
        );
      
        return (
          <React.Fragment>
          {showSettingsModal && <Backdrop onClick={closeSettingsModalHandler} />}
            <SettingsSideDrawer show={showSettingsModal} >
                <button onClick={reloadPageHandler} className={updateAvailable ? "update-btn" : "update-btn-hidden"}>Update available</button>
                <Settings />
            </SettingsSideDrawer>
            <img onClick={handleOpen} src={settings_icon} width="43px" height="43px" alt="Settings"/>
            <p className="menu-li-text" onClick={handleOpen}>Settings</p>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    {body}
                </Modal>
           </React.Fragment>
        );
      }

export default PasswordModalForSettings;


