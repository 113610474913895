import React from 'react';

import './Avatar.css';

import validURL from '../../../functions/validURL';
const Avatar = props => {
  // if image didn't download 
  // we can refer to the full path
  // so we want to check if the image key is url or key

  let imgBlob = props.image;
  
  if(!validURL(imgBlob)) // means imgBlob keep key to the local storage
  {
    imgBlob = localStorage.getItem(props.image); 
  }

  return (
    <div className={`avatar ${props.className}`} style={props.style}>
      <img
        src={imgBlob}
        alt={props.alt}
        style={{ width: props.width, height: props.width }}
      /> 
    </div>
  );
};

export default Avatar;
