import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import sendGAEvent from '../../../../functions/sendGAEvent';

import './DialogModal.css';          

      const useStyles = makeStyles((theme) => ({
        paper: {
          position: 'absolute',
          outline: 'none',
          top: 300,
          left: 145,
          height: '20vh',
          width: '70vw',
          borderRadius: '1rem',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
      }));
      
const DialogModal = props => {
        const classes = useStyles();
        const [open, setOpen] = useState(false);
        const [inputValue, setInputValue] = useState('');
  
        const handleOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setInputValue('');
          setOpen(false);
        };

        const handleInput = event => {
          setInputValue(event.target.value);
        }

        const handleSubmit = (event) => {
          event.preventDefault();  
          props.submitHandler(inputValue)
          props.type === "tel" && window.open(`https://wa.me/${inputValue}?text=Hi There!%0A%0AThank you for using Octopus - entertainment systems.%0AThe link to your wish is attached here.%0AAtlas Hotels wishing you bon voyage:)%0A%0A${props.userSessionURL}%0A%0AOctopus by DM-TM`);
          
          console.log(props.userSessionURL);
          if (props.type === "tel") {
            try {
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' ,
                           'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
                body: JSON.stringify({ session_id: props.sessionID , phone: inputValue})
              };
              fetch('https://botpower.rendit.tech/api/atlas/SetSessionPhone', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                      console.log(data)
                          // GA logic
                          sendGAEvent('wish list exports via whatsapp',{
                            name: props.sessionID,
                            from: "dialog modal",
                            page_path: "some test path name"
                          })                   
                       });    
            } catch (err) {
            console.log(err);
            }
          }
          if (props.type === "email") {         
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' ,
                           'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
                body: JSON.stringify({ session_id: props.sessionID, email: inputValue})
              };
              console.log(props.userSessionURL);
              fetch('https://botpower.rendit.tech/api/atlas/SetSessionEmail', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                      console.log(data)
                      // GA logic
                      sendGAEvent('wish list exports via email',{
                        name: props.sessionID,
                        from: "dialog modal",
                        page_path: "some test path name"
                      })                   
                    }) 
                    .catch((err) => console.log(err));
              }
         }
      
        const body = (
          <div className={classes.paper}>
            <h2 id="simple-modal-title">{props.title}</h2>
            <p id="simple-modal-description">{props.subtitle}</p>
            <form className="form-container" onSubmit={handleSubmit}>
            <input type={props.type}
                   autoFocus
                   className="input-field" 
                   name={props.name}
                   pattern={props.pattern}
                   value={inputValue}
                   onChange={handleInput} 
                   required />
              <button className="submit-btn">{props.btnText}</button>
            </form>  
          </div>
        );
      
        return (
          <React.Fragment>
                <img onClick={handleOpen} 
                     src={props.imgURL} 
                     width={props.imgWidth}
                     height={props.imgHeight}
                     className={props.classString} 
                     alt={props.imgAlt} />
                     
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    {body}
                </Modal>
           </React.Fragment>
        );
      }

export default DialogModal;


    