import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import withSplashScreen from './hoc/withSplashScreen';
import './App.css';

import MainNavigation from './shared/components/Navigation/MainNavigation';
import MainCarusel from './carusel/MainCarusel';
import ResultsList from './Results/ResultsList';
import SearchResultsList from './Results/SearchResultsList';
import sessionManager from './functions/sessionManager';
import ReactGa from 'react-ga';
import Footer from './shared/components/Navigation/Footer';

import IdleTimerContainer from './shared/components/UIElements/IdleTimerContainer';


function App() {

  useEffect( ()=> {
    ReactGa.initialize("G-7J00BRDV4Y") 
    ReactGa.pageview("/");
    current_hotel_id && sessionManager(current_hotel_id)
  },[])

  let location = useLocation();
  location =location.pathname;
  
  let data = JSON.parse(localStorage.getItem("data")) 
  let Background = localStorage.getItem(data.message.background_img_key) 
  let current_hotel_id = localStorage.getItem("current_hotel_id") 
  let bg_style = location == '/' ? `url(${Background})` : `linear-gradient(0deg, rgb(216,216,216,0.6), rgb(216,216,216,0.6)), url(${Background})`; 
      
  return (
    <div className="App"  style={{  backgroundImage: `${bg_style}`,
                                    position:'fixed',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundAttachment: 'fixed', 
                                    backgroundSize: 'cover',
                                    backgroundClip: 'unset'
                                    }}>          
        <span className="left-side"></span> 
        <span className="right-side"></span>                                               
          <MainNavigation />
            <main className="main">  
                <Route path='/' exact ><MainCarusel /></Route> 
                <Route path='/results/:id'><ResultsList /></Route> 
                <Route path='/search/:input'><SearchResultsList /></Route>                       
            </main>
          <Footer />
          <IdleTimerContainer></IdleTimerContainer>
    </div>
  );
}

export default withSplashScreen(App);
