 export default function sendGAEvent(event_name, payload){
        try {
             window.gtag('event', event_name, payload);          
            }
        catch(e) 
            {
             console.log("something went wrong with google: ", e);
            } 
 } 
  
