import React from 'react';
import ReactDOM from 'react-dom';

import './InvisibleBackdrop.css';

const InvisibleBackdrop = props => {
  return ReactDOM.createPortal(
    <div className="invisible-backdrop" onClick={props.onClick}></div>,
    document.getElementById('backdrop-hook')
  );
};

export default InvisibleBackdrop;
