import sendGAEvent from "./sendGAEvent";

export default function sessionManager(id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,
                   'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
        body: JSON.stringify({ hotel_id: id})
      };
    fetch('https://botpower.rendit.tech/api/atlas/InitSession', requestOptions)
    .then( response => response.json())
    .then(sessionObj => {
        localStorage.setItem('sessionID', sessionObj.message)
        console.log(sessionObj)
    // GA logic
        sendGAEvent('new session started',{
            user_session_id :sessionObj.message
        })   
    })
}