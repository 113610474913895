import React, { useState , useEffect } from 'react';
import * as actionCreators from '../store/actions/actions';
import {connect, useSelector } from 'react-redux';
import TagItem from './TagItem';
import { Grid } from '@material-ui/core' ;
import CloseIcon from '@material-ui/icons/Close';
import sendGAEvent from '../functions/sendGAEvent';
import './TagsList.css';


const TagsList = props => {

  const [state,setState] = useState(0);
  // useEffect( () => {
  //   props.openSideDrawer();
  // }, [state])

  let allCategories = useSelector( state => state.categories);
  let currentCategory = allCategories.cat.filter( el => el.category_id == props.idOfClickedCategory);

   let currentTagsList = props.tagList ? props.tagList : [];

   const onTagClickHandler = tag => {
      sendGAEvent('tag clicked',{
        tag_name: tag,
        from: "tags sidedrawer",
        page_path: "some test path name"
      })     
        currentTagsList.forEach( element => {
              if (element.name === tag.name) {
                tag.isActive = tag.isActive === false ? true : false;
              }
          });
          // props.closeSideDrawer();
          setState(state + 1);
          props.onTagClicked(currentCategory,tag,currentTagsList);
          props.toggleForRender(!props.toggleTagState);
      }
   
    return (
        <React.Fragment>
            <ul className="tags-list"> 
                <Grid container spacing={4}>
                {currentTagsList.map( tag => (                   
                    <TagItem 
                        key={tag.name}
                        isActive={tag.isActive}
                        name={tag.name}
                        clicked={onTagClickHandler.bind(this,tag)} />                                                                 
                 ))}
                </Grid>
            </ul>
        </React.Fragment>
            )
        }
  
    const mapDispatchToProps = dispatch => {
    return {
        onTagClicked: (currentCategory,tag,currentTagsList) => dispatch(actionCreators.getPrevStateOFResults(currentCategory,tag,currentTagsList))
     }
    }
    
    export default connect(null,mapDispatchToProps)(TagsList);        