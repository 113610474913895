import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as actionCreators from '../store/actions/actions';
import ResultItem from './ResultItem';
import ItemSideDrawer from './ItemSideDrawer';
import Title from './Title';
import TagsList from './TagsList';
import ChosenItem from './ChosenItem';
import './ResultsList.css';
import { Grid } from '@material-ui/core' ;
import { getDistance } from 'geolib';
import sendGAEvent from '../functions/sendGAEvent';
import WishlistIndicator from './WishlistIndicator';


var chosenItemVarible = null;

const ResultsList = props => {

  const infoFromURL = useParams();
  const idOfClickedCategory = infoFromURL.id;
 
  const [itemDrawerIsOpen, setItemDrawerIsOpen] = useState(false);
  const [tagsDrawerIsOpen, setTagsDrawerIsOpen] = useState(false);
  const [currentDisplayedItem, setCurrentDisplayedItem] = useState(null);
  const [toggleTagState, setToggleTagState] = useState(false);

  useEffect( () => {
    props.onAllResultsLoading(idOfClickedCategory); 
  }, [])

  let tempCategories = useSelector( state => state.categories);
  // if (!tempCategories) {
    
  // }
  
  let tempCategory = tempCategories.cat.filter( el => el.category_id == idOfClickedCategory); 

  console.log(tempCategory[0]);
  let tags = tempCategory[0].ntags; 
  let allResults = props.allResults
                                                                                                                                                        
  if(allResults) {
    // calculte OPEN / CLOSE code here 
    let d = new Date();
    let currentDay = d.getDay() // the current day in "0-6" format 
    let hour = d.getHours();
    let min = d.getMinutes();
    (hour >= 0 && hour <= 9 ) && (hour = `0${hour}`);   
    (min < 10) &&  (min = `0${min}`);
    let currentTime = (hour + ":" + min); // hour in "hh:mm" format

    const isInRange = (currentTime, range1, range2, range3 ) => {
       if(currentTime >= range1[0] && currentTime <= range1[1]) 
          return true
       
       if(currentTime >= range2[0] && currentTime <= range2[1]) 
          return true

       if(currentTime >= range3[0] && currentTime <= range3[1]) 
          return true
      
       return false;
    } 

    for (let i = 0; i < allResults.length; i++) {
      if(allResults[i].shifts.length!==0 && allResults[i].shifts[currentDay]) {
        let range1 = [allResults[i].shifts[currentDay].morning.start,allResults[i].shifts[currentDay].morning.end] 
        let range2 = [allResults[i].shifts[currentDay].noon.start,allResults[i].shifts[currentDay].noon.end] 
        let range3 = [allResults[i].shifts[currentDay].night.start,allResults[i].shifts[currentDay].night.end] 
         
        isInRange(currentTime, range1 ,range2 ,range3) ? allResults[i].status = "OPEN" : allResults[i].status = "CLOSE" 
      } 
    }

    //calculte distance from Hotel code here 
    let hotelCoordinates = JSON.parse(localStorage.getItem("hotelCoordinates")) 
    for (let i = 0; i < allResults.length; i++) {    
        let placeCoordinates = allResults[i].coordinates;

        let distance = getDistance(placeCoordinates,hotelCoordinates);
        let distanceInKm = (distance / 1000).toFixed(1) 
        allResults[i].distance = distanceInKm;
      } 
    }

 
  const openItemDrawerHandler = itemData => {
    setItemDrawerIsOpen(true);
    chosenItemVarible = itemData;
  };
  
  const closeItemDrawerHandler = () => setItemDrawerIsOpen(false);
        
  const renderNewChosenItem = itemData => {
    setCurrentDisplayedItem(itemData);
    chosenItemVarible = itemData;
  };

  const gaResultFromCaruselHandler = (resultName) => {
    console.log('i`m in gaResultFromCaruselHandler ',resultName);
    sendGAEvent('result clicked',{
      res_name: resultName,
      from: "carusel-results",
      page_path: "some test path name"
    })     
 }

  const signBackEventInGA = () => {
    sendGAEvent('Back btn clicked',{
      from: "results page",
      page_path: "some test path name"
    }) 
  }

  let favoritesItemsArrayParse = JSON.parse(localStorage.getItem('favoritesArray'));
  let favoritesItemsArray = favoritesItemsArrayParse ? favoritesItemsArrayParse : []; 
  let heart_beat_wishlist = favoritesItemsArray.length !== 0 ? <WishlistIndicator sum={favoritesItemsArray.length} /> : null;
   
    let check = allResults.filter(res => res.display === true)
    if (check.length === 0) {
        return <React.Fragment> 
                <TagsList toggleTagState={toggleTagState} toggleForRender={setToggleTagState} tagList={tags} idOfClickedCategory={idOfClickedCategory} />
                <div className="no-found-msg-container">
                  <h2 style={{color: "white", fontSize: "2.5rem"}}>No Results Found...</h2>
                </div>
               </React.Fragment> 
       }

    return <React.Fragment> 
            {!itemDrawerIsOpen && heart_beat_wishlist}
            <TagsList toggleTagState={toggleTagState} toggleForRender={setToggleTagState} tagList={tags} idOfClickedCategory={idOfClickedCategory} />
              <ul className="result-list-normal">
                {console.log('list renderd')}
                   <ItemSideDrawer show={itemDrawerIsOpen}>
                    {chosenItemVarible && <ChosenItem itemDataObject={chosenItemVarible} closeSideDrawer={closeItemDrawerHandler} />}   
                   </ItemSideDrawer>

                <Grid container spacing={3} className={itemDrawerIsOpen && "result-list-withRightDrawerOpen"}>                                      
                    {allResults.map( result => (
                      (result.display === true) && 
                      <Grid key={"@kk" + result.place_id}
                            item 
                            xs={4}
                            xs={ itemDrawerIsOpen ? 6 : props.sliderVal}
                         >
                        <ResultItem 
                          id={result.id}
                          currentSliderVal={props.sliderVal}
                          image={result.image}
                          name={result.name}
                          sub_title={result.sub_title}
                          distance={result.distance}
                          status={result.status}
                          iframe={result.iframe}
                          clicked={ gaResultFromCaruselHandler.bind(this,result.name),
                                    !itemDrawerIsOpen ? 
                                    openItemDrawerHandler.bind(this,result) :
                                    renderNewChosenItem.bind(this,result) } />                                                        
                      </Grid>
                     ))}
                </Grid>        
            </ul>
        <Link onClick={signBackEventInGA} to="/" style={{ textDecoration: 'none'}} >
         <Title title={tempCategory[0].category_name} />
        </Link>
    </React.Fragment>
}

const mapStateToProps = state => {
  return {
    sliderVal: state.slider.sliderValue,
    allResults: state.allResults.allRes,
    searchResults: state.searchResults.searchRes
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onAllResultsLoading: (idOfClickedCategory) => dispatch(actionCreators.getAllResultsFromServer(idOfClickedCategory))
 }
}

export default connect(mapStateToProps,mapDispatchToProps)(ResultsList);