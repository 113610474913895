import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import Modal from 'react-modal';
// import video from '../../../images/video.mp4'

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import sessionManager from '../../../functions/sessionManager';
import './IdleTimerContainer.css';

const IdleTimerContainer = () => {
   
    const [idleModalIsOpen,setIdleModalIsOpen] = useState(false);
    const idleTimerRef = useRef(null);
    const MySwal = withReactContent(Swal);
    const history = useHistory();

    Modal.setAppElement('#root');
   
    const onIdle = () => {
        console.log("user is idle");
        setIdleModalIsOpen(true);
    }

    const closeIdleModal = () => {
        setIdleModalIsOpen(false);
        startNewSessionAfterTour();
    }
    
    const startNewSessionAfterTour = () => {
        // remove old session data from local storage (UI)
        let favs = JSON.parse(localStorage.getItem("favoritesArray")) 
        let favsId = JSON.parse(localStorage.getItem("favoritesIdArray")) 
        favs = []
        favsId = []
        localStorage.setItem("favoritesArray", JSON.stringify(favs)) 
        localStorage.setItem("favoritesIdArray", JSON.stringify(favsId))
    
        let hotel_id = localStorage.getItem("current_hotel_id");
        sessionManager(hotel_id);
      
        MySwal.fire({
          didOpen: () => {
            MySwal.clickConfirm()
          }
        }).then(() => {
          return MySwal.fire(<p>Let's go!</p>)
        })
        history.replace("/");
      }

    const customStyles = {
        content: {
          // inset: 0,
          padding: 0,
          top:0,
          left:0,
          width: '100%',
          height: '100%',
          background: '#000', 
          border: 'none',
          borderRadius: 0        
        },
      };
   
      let data = JSON.parse(localStorage.getItem("data")) 
      let idle_video_url = localStorage.getItem(data.message.idle_video_url) 

    return (
        <>
          <Modal isOpen={idleModalIsOpen} style={customStyles}>
          <video width="100%" height="100%" loop autoPlay muted >
             <source src={idle_video_url} type="video/mp4" /> 
          </video>
             <button className="exit-idle-btn" onClick={closeIdleModal}>Start Planning</button>
          </Modal>
          <IdleTimer ref={idleTimerRef} timeout={ 900 * 1000 } onIdle={onIdle}></IdleTimer>    
        </>
    )
}


export default IdleTimerContainer;
