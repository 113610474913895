import React from 'react';

import Card from '../../../UIElements/Card';
import './HotelItem.css';

// const images = require.context('../../../../../images/hotels_imgs', true);

const HotelItem = props => {
   return (
   <li className="hotel-result-item">
     <Card className="hotel-result-item__content">
        <div onClick = {props.clicked} className="hotel-result-item__image">
           <img src={props.image} alt="img" width="100%" height="100%" />
           {/* <img src={images(props.image)} alt="img" width="100%" height="100%" /> */}
        </div> 
        <div className="hotel-result-item__info" onClick = {props.clicked}>
            <p className="the-hotel-name">{props.hotelName}</p>
            {/* <p className="city">{props.city}</p>    */}
        </div>
     </Card>
    </li>
   )
}
export default HotelItem;