import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.png';
import back from '../../../images/back.png';
import Weather from '../Navigation/Weather'
import './Footer.css';

const Footer = () => {
      const pathname  = window.location.pathname;
      let logo_or_back =  pathname === '/' 
      ?  
      <Link to="/" ><img className="link-logo" src={logo} width="125px" height="45px" alt="logo"/></Link> 
      :
      <Link to="/" ><img className="link-logo" src={back} width="50px" height="50px" alt="logo"/></Link>
  return <div className="footer">
               {logo_or_back}
               <Weather />
          </div>
}

export default Footer;