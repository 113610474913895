import React, {Component} from 'react';
// import data from '../data.json';
import SplashContent from './SplashContent';
import localStorageManager from './localStorageManager';
import sendGAEvent from '../functions/sendGAEvent';
import './withSplashScreen.css';

function LoadingMessage() {
  return (
      <SplashContent />
  );
}

function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      try {
          // Simple POST request with a JSON body using fetch\
          // Try to read hotel id from local storage , if null , set defaul to 1
          let hotelId = localStorage.getItem("hotel_id");
          hotelId = !hotelId ? 1 : hotelId;
          console.log(hotelId);
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,
                       'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
            body: JSON.stringify({ hotel_id: hotelId})
          };
          await fetch('https://botpower.rendit.tech/api/atlas/GetHotel', requestOptions)
                .then(response => response.json())
                .then(data => {
                  sendGAEvent('splash',{
                    event: "app load",
                    from: "splash",
                    page_path: "some test path name"
                  })     
                  localStorageManager(data)
                });

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 3000)



      } catch (err) {
        console.log(err);
        this.setState({
          loading: false,
        });
      }
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return LoadingMessage();

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withSplashScreen;