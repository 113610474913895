import React, {useState, useEffect} from 'react';
import './Weather.css';

const Weather = () => {
   
    const [icon,setIcon] = useState('10d');
    const [weatherValue,setWeatherValue] = useState(27);

    useEffect(()=>{
      let city_name = "Tel Aviv";
      let key = "90df795ad1ea125be0168cb635ea1924";

      fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city_name}&appid=${key}`)
      .then( res => res.json())
      .then( data => {
        let temperature = Math.floor(data.main.temp - 273.15);
        setWeatherValue(temperature)
        let currentIcon = data.weather[0].icon;
        setIcon(currentIcon)
      })
      .catch(err => console.log(err))          
    },[weatherValue])
  
      return (
            <div className="weather-wrapper">
              <p className="temp-val">{weatherValue}&deg;</p>
              <img src={`http://openweathermap.org/img/wn/${icon}@2x.png`} alt="icon" width="50px" height="50px" />      
            </div>
      );
}

export default Weather;