import React from 'react';

// import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import './Title.css';

const Title = props => {
   return (
        <div className="title-wrapper">
             {/* <ArrowLeftIcon style={{fontSize:"5rem", marginRight:"-30px"}} /> */}
             <p className="title">{props.title}</p>
        </div>
     )
}
export default Title;