import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { StylesProvider } from "@material-ui/core/styles";
import sessionManager from '../../../functions/sessionManager';
import ConfirmNewSession from '../UIElements/ConfirmNewSession';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import hamburger from '../../../images/hamburger .png';
import X from '../../../images/X .png';
import settings_icon from '../../../images/settings_icon.png';
import new_session from '../../../images/new_session.png';
import terms_icon from '../../../images/terms_icon.png';
import barcode from '../../../images/barcode.png';
import trip from '../../../images/trip.png';
import tour from '../../../images/tour.png';
import PasswordModalForSettings from '../Navigation/Settings/PasswordModalForSettings';
import TermsModal from '../Navigation/TermsModal';
import TourComponent from './TourComponent';
import './Menu.css';


const SimpleMenu = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tourIsOpen, setTourIsOpen] = useState(false);
  const history = useHistory();
  const MySwal = withReactContent(Swal)

  useEffect( ()=> {
    console.log(tourIsOpen);
    !tourIsOpen && setTourIsOpen(true)
  },[tourIsOpen])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseForTour = () => {
    setAnchorEl(null);
    tourIsOpen && setTourIsOpen(false)
  };

  const openNewSessionModal = () => {
    setAnchorEl(false);
    console.log('in the function');
    setShowConfirmModal(true);
  }

  const startNewSession = () => {
    // remove old session data from local storage (UI)
    let favs = JSON.parse(localStorage.getItem("favoritesArray")) 
    let favsId = JSON.parse(localStorage.getItem("favoritesIdArray")) 
    favs = []
    favsId = []
    localStorage.setItem("favoritesArray", JSON.stringify(favs)) 
    localStorage.setItem("favoritesIdArray", JSON.stringify(favsId))

    let hotel_id = localStorage.getItem("current_hotel_id");
    sessionManager(hotel_id);
    setShowConfirmModal(false);
  
    MySwal.fire({
      didOpen: () => {
        MySwal.clickConfirm()
      }
    }).then(() => {
      return MySwal.fire(<p>Let's go!</p>)
    })
    history.replace("/");
  }

  const openTour = () => {
    history.replace("/");
    setTourIsOpen(true);
  }

  return (
    <div>
      {tourIsOpen && <TourComponent />}
      {console.log(tourIsOpen)}
      <ConfirmNewSession show={showConfirmModal} >
        <h4 className="new-planning-title">Starting New Planning?</h4>
        <img className="trip-img" src={trip} width="180px" height="180px" alt="warrning"/>
        <div className="btns-wrapper">
         <button className="confirm-btn" onClick={startNewSession}>Yes</button>
         <button className="cancel-btn" onClick={() => setShowConfirmModal(false)}>No</button>
        </div>
      </ConfirmNewSession>

      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
         { !anchorEl ? <img id="menu-img" style={{marginRight: '-45px'}} src={hamburger} width="71px" height="45px" alt="menu"/> : <img onClick={handleClose} style={{marginRight: '-45px'}} src={X} width="71px" height="45px" alt="menu"/> }       
      </Button>
       <StylesProvider injectFirst>
        <Menu
          style={{top:"62px", left: "16px"}}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} style={{fontSize:"1.75rem"}}>
          <img onClick={props.openPersonalModal} src={barcode} width="38px" height="38px" alt="GuestWL"/> 
          <p className="menu-li-text" onClick={props.openPersonalModal}>My Wishlist</p> 
          </MenuItem>
          <hr style={{width:"309px"}} />
          <MenuItem onClick={openNewSessionModal} style={{fontSize:"1.75rem"}}>
          <img onClick={openNewSessionModal} src={new_session} width="38px" height="38px" alt="New"/>
          <p className="menu-li-text" onClick={props.openNewSessionModal}>Start New Trip</p> 
          </MenuItem>
          <hr style={{width:"309px"}} />
          <MenuItem style={{fontSize:"1.75rem"}}>
          {/* <img src={terms_icon} width="42px" height="42px" alt="New"/>
          <p className="menu-li-text">Terms Of Use</p>  */}
          <TermsModal /> 
          </MenuItem>
          <hr style={{width:"309px"}} />
          <MenuItem onClick={handleCloseForTour} style={{fontSize:"1.75rem"}}>
          <img onClick={openTour} src={tour} width="50px" height="50px" alt="GuestWL"/> 
          <p className="menu-li-text" onClick={openTour}>Tour</p> 
          </MenuItem>
          <hr style={{width:"309px"}} />
          <MenuItem onClick={handleClose} style={{fontSize:"1.75rem"}}>
          {/* <img onClick={props.openSettingsDrawer} src={settings_icon} width="43px" height="43px" alt="Settings"/>
          <p className="menu-li-text" onClick={props.openSettingsDrawer}>Settings</p> */}
           <PasswordModalForSettings />         
          </MenuItem>

      </Menu>
     </StylesProvider>
    </div>
  );
}
export default SimpleMenu; 

