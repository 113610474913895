import React from 'react';

import './Iframe.css';

const Iframe = props => {
  console.log(props);
  return (
    <div className={`item-iframe ${props.className}`} style={props.style}>
      <iframe
        src={`https://my.matterport.com/show/?m=${props.iframe}&nt=1&play=1`}
        style={{ width: props.width, height: props.width }}
        frameBorder="0" 
        allowFullScreen={false}
        allow="vr; xr"
      /> 
    </div>
  );
};

export default Iframe;
