// import axios from 'axios';
// import data from '../../data.json';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_ALL_RESULTS = 'GET_ALL_RESULTS';
export const  GET_FILTERED_RESULTS_BY_TAG = ' GET_FILTERED_RESULTS_BY_TAG';
export const  GET_ALL_SEARCH_RESULTS = ' GET_ALL_SEARCH_RESULTS';


//--------- GET_CATEGORIES --------------///
export const getCategoriesFromServer = () => {
  return dispatch => {
    // return axios.get("https://server/data.json")
    //   .then(response => {
    //     dispatch(formatToCityAndKeyArray(response.data))
    //   });        
    // });
    let data = JSON.parse(localStorage.getItem("data")) 
    let CategoryAraay = data;
      return dispatch(getCategories(CategoryAraay));
  }
};

export const getCategories = (arr) => {
  return {
    type: GET_CATEGORIES,
    categories: arr.message.categories
  };
};

//--------- GET_ALL_RESULTS --------------///

export const getAllResultsFromServer = (idOfClickedCategory) => {
  return dispatch => {
    // return axios.get("https://server/data.json/id")
    //   .then(response => {
    //     dispatch(formatToCityAndKeyArray(response.data))
    //   });        
    // });
    let data = JSON.parse(localStorage.getItem("data")) 
    let theClickedCategory = data.message.categories.filter( el => el.category_id == idOfClickedCategory);
    let allResultsAraay = theClickedCategory[0].places;

      return dispatch(getAllResults(allResultsAraay));
  }
};

export const getAllResults = (arr) => {
  return {
    type: GET_ALL_RESULTS,
    allResults: arr
  };
};

//--------- GET_FILTERED_RESULTS_BY_TAG --------------///

export const getPrevStateOFResults = (currentCategory,tag,currentTagsList) => {
  console.log(currentCategory);
  console.log(tag);
  console.log(currentTagsList);
  return dispatch => {
    // return axios.get("https://server/data.json/id")
    //   .then(response => {
    //     dispatch(formatToCityAndKeyArray(response.data))
    //   });        
    // });
    let currentResults = currentCategory[0].places;

    if (tag.isActive === true) {
      // console.log(currentTagsList);
      for (let i = 0; i < currentResults.length; i++) {
          let tempTagsArray = currentResults[i].tags
          let isTagExist = tempTagsArray.indexOf(tag.name)
          if (isTagExist === -1) {
            currentResults[i].display = false;
          }        
      }
    }


    if (tag.isActive === false) {
      for (let i = 0; i < currentResults.length; i++) {
          currentResults[i].display = true;            
      } 

      console.log(currentResults); 
      
      let activeTagsArray = currentTagsList.filter( tagObj => tagObj.isActive === true );
      console.log(activeTagsArray);
      for (let i = 0; i < activeTagsArray.length; i++) {
          let currentTag = activeTagsArray[i].name
        for (let j = 0; j < currentResults.length; j++) {
          let tempTagsArray = currentResults[j].tags
          let isTagExist = tempTagsArray.indexOf(currentTag)
          if (isTagExist === -1) {
            currentResults[j].display = false;
          }        
        }       
      }
    }

      return dispatch(getFilteredResults(currentResults,tag));
  }
};

export const getFilteredResults = (currentResults,tag) => {
  return {
    type: GET_FILTERED_RESULTS_BY_TAG,
    currentResults: currentResults,
    tag: tag
  };
};

//----------- GET_ALL_SEARCH_RESULTS ------------------////

export const getSearchResultsFromServer = (option) => {
  let searchResultsArr = [];
  let temp = [];
  return dispatch => {
    // return axios.get("https://server/data.json/id")
    //   .then(response => {
    //     dispatch(formatToCityAndKeyArray(response.data))
    //   });        
    // });

    // let theClickedCategory = data.filter( el => el.id == idOfClickedCategory);
    // let allResultsAraay = theClickedCategory[0].results;
    let data = JSON.parse(localStorage.getItem("data")) 
    for (let i = 0; i < data.message.categories.length; i++) {
      let resultsArr = data.message.categories[i].places;
      let filterResByName = resultsArr.filter( res => {
       return res.name === option
      })


      let filterResByTag = resultsArr.filter( res => {
        return (res.tags.indexOf(option) !== -1)
      });  
      
      temp = filterResByName.concat(filterResByTag)
      searchResultsArr = searchResultsArr.concat(temp)
    }  
      
      console.log(searchResultsArr);
      // handels duplicate items
      let setObj = new Set();       
      searchResultsArr = searchResultsArr.reduce((acc,item)=>{
        if(!setObj.has(item.place_id)){
          setObj.add(item.place_id,item)
          acc.push(item)
        }
        return acc;
      },[]);//converting back to array from mapobject
      console.log(searchResultsArr);
      return dispatch(getSearchResults(searchResultsArr));
 }
}

export const getSearchResults = (searchResultsArr) => {
  return {
    type: GET_ALL_SEARCH_RESULTS,
    searchResults: searchResultsArr
  };
};
