import React from 'react';

import Avatar from '../shared/components/UIElements/Avatar';
import Card from '../shared/components/UIElements/Card';
import './ResultItem.css';

const ResultItem = props => {
    // console.log(props.currentSliderVal);
   return (
   <li className="result-item">
     <Card className={ props.currentSliderVal === 3 ? "result-item__content-small" : "result-item__content"} >
        <div onClick = {props.clicked} className="result-item__image">
            <Avatar image={props.image} alt={props.alt} />
        </div> 
        <div className="result-item__info" onClick = {props.clicked}>
            <p className={ props.currentSliderVal === 3 ? "name-small-mode" : "name"}>{props.name}</p>
            <p className={ props.currentSliderVal === 3 ? "sub-title-small-mode" : "sub-title"}>{props.sub_title}</p>
            <div className={ props.currentSliderVal === 3 ? "result-item__buttom-info-small" : "result-item__buttom-info"} >
                <p className="distance">{`${props.distance} KM`}</p>
                {/* <p className="description">Discount</p> */}
                <p className={props.status === "OPEN" ? "open" : "close" }>{props.status}</p>
            </div>    
        </div>
     </Card>
    </li>
   )
}
export default ResultItem;