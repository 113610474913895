import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import HotelsSideDrawer from './HotelsSideDrawer';
import Backdrop from '../../../UIElements/Backdrop';
import HotelsList from './HotelsList';

import './PasswordModal.css';          

      const useStyles = makeStyles((theme) => ({
        paper: {
          position: 'absolute',
          outline: 'none',
          top: 300,
          left: 145,
          height: '20vh',
          width: '70vw',
          borderRadius: '1rem',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
      }));
      
const PasswordModal = () => {
        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const [inputValue, setInputValue] = React.useState('');
        const [showHotelsModal, setShowHotelsModal] = React.useState(false);
        const [msg, setMsg] = React.useState();
      
        const handleOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setMsg(null)
          setInputValue('');
          setOpen(false);
        };

        const handleInput = event => {
          setInputValue(event.target.value);
        }

        const handleSubmit = event => {
          event.preventDefault();
          if (inputValue === '12345') {
            console.log('loading hotel list...');
            setInputValue('');
            setMsg(null)
            handleClose();
            setShowHotelsModal(true)
          }else{
            console.log('wrong password...');
            setInputValue('');
            setMsg('wrong password...')
          }
        }

        const closeHotelsModalHandler = () => setShowHotelsModal(false);
      
        const body = (
          <div className={classes.paper}>
            <h2 id="simple-modal-title">Administrator area</h2>
            <p id="simple-modal-description">You need to put password to perform this action.</p>
            <form className="form-container" onSubmit={handleSubmit}>
              <input 
              autoFocus 
              className="input-password"
              type="password" 
              value={inputValue}
              onChange={handleInput} /> 
              <button className="submit-btn">Enter</button>
            </form>  
             {msg && <p className="wrong-password">{msg}</p>}   
          </div>
        );
      
        return (
          <React.Fragment>
          {showHotelsModal && <Backdrop onClick={closeHotelsModalHandler} />}
          <HotelsSideDrawer show={showHotelsModal} >
            <HotelsList setShowHotelsModal={setShowHotelsModal} />
          </HotelsSideDrawer>
            <button onClick={handleOpen} className="change-hotel-btn">
                change hotel
            </button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    {body}
                </Modal>
           </React.Fragment>
        );
      }

export default PasswordModal;


