import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

 function getModalStyle() {
  return {
    top: "30%",
    left: "15%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    outline: 'none',
    top: 300,
    left: 145,
    height: '20vh',
    width: '70vw',
    borderRadius: '1rem',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ConNewHotelModal = props => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">CHANGE HOTEL</h2>
      <p id="simple-modal-description">
        Are you sure? click "Yes" if you sure, click on the background otherwise.
      </p>
      <form className="form-container" action="/">
         <button className="submit-btn">Yes</button>
      </form>      
      <ConNewHotelModal />
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default ConNewHotelModal;