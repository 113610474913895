import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import sendGAEvent from '../../../functions/sendGAEvent';
// import data from '../../../data.json';

import InvisibleBackdrop from './InvisibleBackdrop';
import './Input.css';

const Auto = props => {
  const [display, setDisplay] = useState(true);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [choise, setChoise] = useState(false);
  
  let data = JSON.parse(localStorage.getItem("data"));

  useEffect( () => {
    const allOptionsWithDuplicate = [];
    for (let i = 0; i < data.message.categories.length; i++) { 
      const places = [];
      const tagsArr = [];
      let cat = data.message.categories[i];
      let tagList = cat.ntags.filter(element => element.name);
      for (let j = 0; j < tagList.length; j++) {
        tagsArr.push(tagList[j].name)       
      } 
      for (let j = 0; j < cat.places.length; j++) {
        places.push(cat.places[j].name)         
      }   
      let temp = places.concat(tagsArr);
      temp.forEach(element => {
        allOptionsWithDuplicate.push(element);
      });     
    }
    const allOptions = [...new Set(allOptionsWithDuplicate)]
    setOptions(allOptions);
  },[]);

  const setOptionHandler = (option) => {
    sendGAEvent('search input',{
      search_input: option,
      from: "search option",
      page_path: "some test path name"
    })     
    setSearch(option);
    setDisplay(false);
    setChoise(true);
    getInputResults(option);   
  }


  const getInputResults = option => props.onSearch(option); 
  const content = (
        <div className="modal__input-content">
          <input className="modal_input" 
                 type="text"
                 placeholder="Search Type" 
                 onClick={ () => setDisplay(true)} 
                 autoFocus
                 value={search}
                 onChange={ event => setSearch(event.target.value)}
                />                                
          { display && <div className="auto-container">
                          {options
                          .filter( name => name.toLowerCase().indexOf(search.toLowerCase()) > -1)
                          .map((v,i) => {
                           return (
                            <div onClick={() => setOptionHandler(v)} 
                                 className={i%2==0 ? "odd-option" : "option" } 
                                 key={i} 
                                 tabIndex="0">
                               <span className="span-option">{v}</span>
                            </div>
                           ) 
                          })}
                       </div>}
          </div>
  );
  if (choise) {
    return (
      <React.Fragment>
        <InvisibleBackdrop onClick={props.onCancel()} />
        <Redirect to={`/search/${search}`} />
      </React.Fragment>)
  }
 
  return ReactDOM.createPortal(content, document.getElementById('input-hook'));
};

const Input = props => {
  return (
    <React.Fragment>
      {props.show && <InvisibleBackdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={20}
        classNames="modal"
      >
        <Auto {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};


const mapDispatchToProps = dispatch => {
  return {
    onSearch: (search) => dispatch(actionCreators.getSearchResultsFromServer(search))
 }
}

export default connect(null,mapDispatchToProps)(Input);

