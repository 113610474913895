import React, { useState, useEffect } from 'react';
import HotelItem from './HotelItem';
import './HotelsList.css';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
// import hotelsData from '../../../../../hotelsData.json';
import ConNewHotelModal from './ConNewHotelModal';

const HotelsList = () => {
    const [hotelsList,setHotelsList] = useState([]);
    const [filterdHotelsList,setFilterdHotelsList] = useState([]);
    const [open, setOpen] = useState(false);


    useEffect(()=> {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,
                   'botPowerKey' : 'e7fa0c96e7514a90b2cc5acf264f001d'},
      };
      fetch('https://botpower.rendit.tech/api/atlas/GetHotels', requestOptions)
            .then(response => response.json())
            .then(hotels => {
              setHotelsList(hotels.message);
              setFilterdHotelsList(hotels.message);
              console.log(hotels)
            })
            .catch( err => console.log(err, "can't get hotels.."));
    },[])



  

       
    const inputHandler = input => {
        const fiterdHotels = hotelsList.filter( el => el.hotelName.toLowerCase().indexOf(input.toLowerCase()) > -1)
        setFilterdHotelsList(fiterdHotels)
    }

    const loadNewHotel = id => {
       localStorage.setItem("hotel_id",id);
       console.log(id);
        setOpen(true);
      }

    const handleClose = () => {
       let id = localStorage.getItem("current_hotel_id");
       localStorage.setItem("hotel_id",id);
        setOpen(false);
      };
      
    console.log(filterdHotelsList);
    console.log(hotelsList);
    if (hotelsList.length === 0 || filterdHotelsList.length === 0) {
      return <React.Fragment> 
                <header className="hotel-header-container">
                    <h1 className="hotel-header-title">Atlas Hotels List</h1>
                    <div className="hotel-search-wrapper">
                    <SearchIcon style={{ fontSize: '45px'}} />
                    <input 
                        autoFocus 
                        className="hotel-input-line" 
                        type="text"
                        onChange={e => inputHandler(e.target.value)} />
                    </div>            
                </header>
                <div className="hotel-no-found-msg-container">
                  <h2 style={{color: "black", fontSize: "2.5rem"}}>No Hotels Found...</h2>
                </div>
             </React.Fragment> 
      }

    return <React.Fragment>   
            {open && <ConNewHotelModal open={open} handleClose={handleClose}>Hi</ConNewHotelModal> }     
            <header className="hotel-header-container">
                <h1 className="hotel-header-title">Atlas Hotels List</h1>
                <div className="hotel-search-wrapper">
                <SearchIcon style={{ fontSize: '45px'}} />
                <input 
                    autoFocus 
                    className="hotel-input-line" 
                    type="text"
                    onChange={e => inputHandler(e.target.value)} />
                </div>            
            </header>
            <ul className="hotel-result-hotel-list">
                <Grid container spacing={2}>               
                    {filterdHotelsList.map( result => (
                      <Grid key={result.hotel_id}
                            item 
                            xs={4} >
                        <HotelItem 
                          id={result.hotel_id}
                          image={result.background_img}
                          hotelName={result.name}
                          // city={result.city}
                          clicked={loadNewHotel.bind(this,result.hotel_id)} 
                        />                    
                      </Grid>
                  ))}
                </Grid>
            </ul>
    </React.Fragment>
}

export default HotelsList;