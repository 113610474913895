import React from 'react';

import atlas_logo from './atlas_logo.PNG';
import splash from './splash.gif';
import './SplashContent.css';

const SplashContent = () => {
    return (
        <div className="splash-container">
            <div className="splash-headers">
              <h1>Octopus</h1>
              <h2>Presented by</h2>
            </div>
            <div className="splash-imgs">
              <img style={{marginLeft:'70px'}} src={atlas_logo} alt="loading..." width="680px" height="150px" /><br/><br/><br/>
              <img src={splash} alt="loading..." width="315rem" height="152rem" />
            </div>
        </div>
      );
}

export default SplashContent;