import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import ResultItem from './ResultItem';
import ItemSideDrawer from './ItemSideDrawer';
import ChosenItem from './ChosenItem';
import './ResultsList.css';
import { Grid } from '@material-ui/core';
import { getDistance } from 'geolib';
import Title from './Title';
import sendGAEvent from '../functions/sendGAEvent';

var chosenItemVarible = null;

const SearchResultsList = props => {

  const infoFromURL = useParams();
  const searchName = infoFromURL.input;
  console.log(searchName);
 
  const [itemDrawerIsOpen, setItemDrawerIsOpen] = useState(false);
  const [currentDisplayedItem, setCurrentDisplayedItem] = useState(null);

  if(props.searchResults) {
    let d = new Date();
    let currentDay = d.getDay() // the current day in "0-6" format 
    let hour = d.getHours();
    let min = d.getMinutes();
    (hour >= 0 && hour <= 9 ) && (hour = `0${hour}`);   
    (min < 10) &&  (min = `0${min}`);
    let currentTime = (hour + ":" + min); // hour in "hh:mm" format

    const isInRange = (currentTime, range1, range2, range3 ) => {
       if(currentTime >= range1[0] && currentTime <= range1[1]) 
          return true
       
       if(currentTime >= range2[0] && currentTime <= range2[1]) 
          return true

       if(currentTime >= range3[0] && currentTime <= range3[1]) 
          return true
      
       return false;
    } 
  
    for (let i = 0; i < props.searchResults.length; i++) {
      if (props.searchResults[i].shifts.length !== 0) {
        let range1 = [props.searchResults[i].shifts[currentDay].morning.start,props.searchResults[i].shifts[currentDay].morning.end] 
        let range2 = [props.searchResults[i].shifts[currentDay].noon.start,props.searchResults[i].shifts[currentDay].noon.end] 
        let range3 = [props.searchResults[i].shifts[currentDay].night.start,props.searchResults[i].shifts[currentDay].night.end] 
        isInRange(currentTime, range1 ,range2 ,range3) ? props.searchResults[i].status = "OPEN" : props.searchResults[i].status = "CLOSE"
      }     
    }

    // calculte distance from Hotel code here 
    let hotelCoordinates = JSON.parse(localStorage.getItem("hotelCoordinates")) 
    for (let i = 0; i < props.searchResults.length; i++) {
      let placeCoordinates = props.searchResults[i].coordinates;

      let distance = getDistance(placeCoordinates,hotelCoordinates);
      let distanceInKm = (distance / 1000).toFixed(1) 
      props.searchResults[i].distance = distanceInKm;
    } 
  }

  const openItemDrawerHandler = itemData => {
    setItemDrawerIsOpen(true);
    chosenItemVarible = itemData;
  };
  
  const closeItemDrawerHandler = () => setItemDrawerIsOpen(false); 
          
  const renderNewChosenItem = itemData => {
    setCurrentDisplayedItem(itemData);
    chosenItemVarible = itemData;
  };

    const gaResultFromSearchlHandler = (resultName) => {
    console.log('i`m in gaResultFromSearchlHandler ',resultName);
    sendGAEvent('result clicked',{
      res_name: resultName,
      from: "search-results",
      page_path: "some test path name"
    })   
  }

    if (props.searchResults.length === 0) {
      return <React.Fragment> 
                <div className="no-found-msg-container">
                  <h2 style={{color: "white", fontSize: "2.5rem"}}>No Results Found...</h2>
                </div>
             </React.Fragment> 
      }

    return <React.Fragment> 
       <div style={{paddingTop:"45px"}}>
        <Title title={searchName} />
       </div>  
                         
            <ul className="result-list-normal">

                  <ItemSideDrawer show={itemDrawerIsOpen}>
                    {chosenItemVarible && <ChosenItem itemDataObject={chosenItemVarible} closeSideDrawer={closeItemDrawerHandler} />}   
                  </ItemSideDrawer>

                <Grid container spacing={3} className={itemDrawerIsOpen && "result-list-withRightDrawerOpen"}>
                  
                    { props.searchResults.map( result => (
                      <Grid key={"@#" + result.id}
                            item 
                            xs={ itemDrawerIsOpen ? 6 : props.sliderVal} >
                        <ResultItem 
                          id={result.id}
                          currentSliderVal={props.sliderVal}
                          image={result.image}
                          name={result.name}
                          sub_title={result.sub_title}
                          distance={result.distance}
                          status={result.status}
                          iframe={result.iframe}
                          clicked={ gaResultFromSearchlHandler.bind(this,result.name),
                                    !itemDrawerIsOpen ? 
                                    openItemDrawerHandler.bind(this,result) :
                                    renderNewChosenItem.bind(this,result) } />    
                                                      
                      </Grid>
                  ))}
                </Grid>
            </ul>
    </React.Fragment>
}

const mapStateToProps = state => {
  return {
    sliderVal: state.slider.sliderValue,
    searchResults: state.searchResults.searchRes
  };
}

// const mapDispatchToProps = dispatch => {
//   return {
//     onAllResultsLoading: (idOfClickedCategory) => dispatch(actionCreators.getAllResultsFromServer(idOfClickedCategory))
//  }
// }

export default connect(mapStateToProps,null)(SearchResultsList);