import React, { useState } from 'react';
import ModalPersonal from '../shared/components/UIElements/ModalPersonal';
import ExportWishList from '../shared/components/Navigation/Personal/ExportWishList';
import WishList from '../shared/components/Navigation/Personal/WishList';
import wish_list_add from '../images/wish_list_add.png'
import './WishlistIndicator.css';

const WishlistIndicator = props => {

   const [showModal, setShowModal] = useState(false);

   const openModalHandler = () => {
      setShowModal(true);
    };

    const closeModalHandler = () => {
      setShowModal(false);
    };

   return (
     <>
           <ModalPersonal 
               show={showModal} 
               onCancel={closeModalHandler}
               header='Personal'
               footer={<ExportWishList />} >
             <WishList />
            </ModalPersonal>

         <span onClick={openModalHandler} className={props.sum < 10 ? "sum" : "two-sum"}>{`${props.sum}`}</span>
         <div onClick={openModalHandler} className="heart"><img src={wish_list_add} width="40px" height="40px" /></div>
         {/* <div onClick={openModalHandler} className="heart">&#x2665;</div> */}
     </> 
   );
}

export default WishlistIndicator;