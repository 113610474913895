import * as actionTypes from '../actions/actions';

const initialState = {
       allRes: [
        {
        id: 11,
        display: true,
        tags: ["kosher","Disabled_friendly"],
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQnXPwjOnE_hqRxUSUeKE0flBrApk1Au1w58Q&usqp=CAU",
        secImages : [
          "https://pizzangel.co.il/wp-content/uploads/2020/07/bg_0009_37jpg-1030x579.jpg",
          "https://pizzangel.co.il/wp-content/uploads/2020/07/bg_0009_37jpg-1030x579.jpg",
          "https://pizzangel.co.il/wp-content/uploads/2020/07/bg_0009_37jpg-1030x579.jpg"
          ],
        name: "INITIAL STATE",
        coordinates: { 
          latitude: 51.5103, 
          longitude: 7.49347 
                },
        distance: "",
        shifts: [
          {
            morning : {
                        start: "08:00",
                        end : "13:00"
                        }, 
            noon : {
                        start: "14:00",
                        end : "18:00"
                        }, 
            night : {
                        start : "20:00",
                        end : "23:59"
                        } 
            },
            {
              morning : {
                          start: "08:00",
                          end : "13:00"
                          }, 
              noon : {
                          start: "14:00",
                          end : "18:00"
                          }, 
              night : {
                          start : "20:00",
                          end : "23:59"
                          } 
              },
              {
                morning : {
                            start: "08:00",
                            end : "13:00"
                            }, 
                noon : {
                            start: "14:00",
                            end : "18:00"
                            }, 
                night : {
                            start : "20:00",
                            end : "23:59"
                            } 
                },
                {
                  morning : {
                              start: "10:00",
                              end : "23:00"
                              }, 
                  noon : {
                              start: "14:00",
                              end : "18:00"
                              }, 
                  night : {
                              start : "20:00",
                              end : "23:59"
                              } 
                  },
                  {
                    morning : {
                                start: "08:00",
                                end : "13:00"
                                }, 
                    noon : {
                                start: "14:00",
                                end : "18:00"
                                }, 
                    night : {
                                start : "20:00",
                                end : "23:59"
                                } 
                    },
                    {
                      morning : {
                                  start: "08:00",
                                  end : "13:00"
                                  }, 
                      noon : {
                                  start: "14:00",
                                  end : "18:00"
                                  }, 
                      night : {
                                  start : "20:00",
                                  end : "23:59"
                                  } 
                      },
                      {
                        morning : {
                                    start: "08:00",
                                    end : "13:00"
                                    }, 
                        noon : {
                                    start: "14:00",
                                    end : "18:00"
                                    }, 
                        night : {
                                    start : "20:00",
                                    end : "23:59"
                      } 
            },
          ],
        status : "close",
        iframe: "https://my.360spaces.co.il/show/?m=atlas_tal_hotel"            
        }                                                                                                                                                           
       ]            
     };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_RESULTS:
      return {
        ...state,
        allRes: action.allResults
      }
    case actionTypes.GET_FILTERED_RESULTS_BY_TAG:
      return {
        ...state,
        allRes: action.currentResults,
      }
  }
  return state;
};

export default reducer;