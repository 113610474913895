import React, {useState} from 'react';

import Avatar from '../../UIElements/Avatar';
import Card from '../../UIElements/Card';
import './WishListItem.css';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const WishListItem = props => {
   console.log(props);
   return (
       <li className="result-wish-item">
         <Card className="result-wish-item__content">
            <div onClick={props.clicked} className="result-wish-item__image">
               <Avatar image={props.image} alt={props.alt} />
            </div> 
            <ClearOutlinedIcon onClick={props.remove} style={{fontSize:"2rem"}} className="remove-item-btn" />
            <div className="result-wish-item__info" onClick={props.clicked}>
                  <p className="name">{props.name}</p>
                  <p className="description">{props.sub_title}</p>
                  <div className="result-wish-item__buttom-info">                  
                   <p className="distance">{`${props.distance} KM`}</p>
                     <p className={props.status === "OPEN" ? "open" : "close"  } >{props.status}</p>
                  </div>    
            </div>
         </Card>
         </li>
   )
}
export default WishListItem;