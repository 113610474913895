// let currentDisplayValue = JSON.parse(localStorage.getItem('currentDisplayValue'));

const initialState = {
    sliderValue: 4
  }

const sliderReducer = (state = initialState, action) => {
   if (action.type === 'SLIDER_CHANGE') {
       if (action.value === 4) {
        //  localStorage.setItem('currentSize', true); // means BIG
        //  localStorage.setItem('currentDisplayValue', 4);
         action.value = 4;
       }
       else if (action.value === 3) {
        // localStorage.setItem('currentSize', false); // means SMALL
        //  localStorage.setItem('currentDisplayValue', 3);
         action.value = 3;
       }

       return {
        sliderValue: action.value,
       }
   }
    return state;
}

export default sliderReducer;