import * as actionTypes from '../actions/actions';


let initialArr = [];
let data = JSON.parse(localStorage.getItem("data")) 
// for the app not to colapse in routes like: /results/5
for (let i = 0; i < 20; i++) {
  initialArr.push(  {
    category_id: i,
    categoryName: "INITIAL STATE ",
    image: "taly.jpg",
    ntags: [{id:"111",name:"initial state",isActive:false}],           
  })

  
}
const initialState = {
        cat: initialArr
     };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES:
      return {
        ...state,
        cat: action.categories
      }
  }
  return state;
};

export default reducer;