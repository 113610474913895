import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Pannellum } from "pannellum-react";

import './DemoCarousel.css';


class DemoCarousel extends Component {
    render() {
    
    function img_size(url) {
        var img = new Image();
        img.src = url;
        return (img.width/2 >= img.height); 
    }
    
    let is3601 = img_size(this.props.itemDataObject.secImages[0]);
    let is3602 = img_size(this.props.itemDataObject.secImages[1]);
    let is3603 = img_size(this.props.itemDataObject.secImages[2]);

    console.log(is3601);
    console.log(is3602);
    console.log(is3603);
     
        
    let img360_1 = <Pannellum
                        width="648px"
                        height="600px"
                        image={`${this.props.itemDataObject.secImages[0]}?x-request=html`}
                        pitch={10}
                        yaw={180}
                        hfov={110}
                        autoLoad
                        autoRotate={10}
                        showZoomCtrl={false}
                        >
                    <Pannellum.Hotspot
                        type="custom"
                        pitch={12.41}
                        yaw={117.76}
                        handleClick={(evt, name) => console.log(name)}
                        name="image info"
                        />
                    </Pannellum> 
    let img360_2 = <Pannellum
                        width="648px"
                        height="600px"
                        image={`${this.props.itemDataObject.secImages[1]}?x-request=html`}
                        pitch={10}
                        yaw={180}
                        hfov={110}
                        autoLoad
                        autoRotate={10}
                        showZoomCtrl={false}
                        >
                    <Pannellum.Hotspot
                        type="custom"
                        pitch={12.41}
                        yaw={117.76}
                        handleClick={(evt, name) => console.log(name)}
                        name="image info"
                    />    
                    </Pannellum>
    let img360_3 = <Pannellum
                        width="648px"
                        height="600px"
                        image={`${this.props.itemDataObject.secImages[2]}?x-request=html`}
                        pitch={10}
                        yaw={180}
                        hfov={110}
                        autoLoad
                        autoRotate={10}
                        showZoomCtrl={false}
                        >
                    <Pannellum.Hotspot
                        type="custom"
                        pitch={12.41}
                        yaw={117.76}
                        handleClick={(evt, name) => console.log(name)}
                        name="image info"
                        />
                    </Pannellum>   

    let img_1 =  <img src={this.props.itemDataObject.secImages[0]} width="648px" height="600px" />
    let img_2 =  <img src={this.props.itemDataObject.secImages[1]} width="648px" height="600px" />
    let img_3 =  <img src={this.props.itemDataObject.secImages[2]} width="648px" height="600px" />

    let pic1  = is3601 ? img360_1 : img_1; 
    let pic2  = is3602 ? img360_2 : img_2; 
    let pic3  = is3603 ? img360_3 : img_3; 

   
    return (
            <Carousel dynamicHeight={false}  
                      showThumbs={false} 
                      showIndicators={false} 
                      swipeable={!is3601 && !is3602 && !is3603 ? true : false} 
                      >
                <div>
                    {pic1}
                </div>
                <div>
                    {pic2}
                </div>
                <div>
                    {pic3}
                </div>
            </Carousel>
        );
    }
}

export default DemoCarousel;