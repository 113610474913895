import React from 'react';

import './ExportWishList.css';
import Grid from '@material-ui/core/Grid';

import wa_icon from '../../../../images/wa_icon.png';
import mail_icon from '../../../../images/mail_icon.png';
import link_icon from '../../../../images/link_icon.png';
import {USER_SESSION_URL} from '../../../../functions/const';
import scan_qr_code from '../../../../images/scan_qr_code.gif';
import DialogModal from './DialogModal';

let sessionID = localStorage.getItem("sessionID") 
let userSessionURL = USER_SESSION_URL + sessionID;
console.log(sessionID);
const submitWhatsappHandler = (state) => {
    console.log(state);
    console.log("submitWhatsappHandler(arg)");
}

const submitEmailHandler = (state) => {
    // sendgride
    console.log(state); 
    let subject="Wish List" 
    let email="info@dm-tm.com" 
    window.open(`mailto:${state}
                 ?bcc=${email}
                 &subject=${subject}
                 &body=Hi There!%0A%0AThank you for using Octopus - entertainment systems.%0AThe link to your wish is attached here.%0AAtlas Hotels wishing you bon voyage:)%0A%0A${userSessionURL}%3Futm_source%3Doctopus%26utm_medium%3Dtablet%26utm_campaign%3Demail%0A%0AOctopus by DM-TM`);                                 
    }

const ExportWishList = () => {
    sessionID = localStorage.getItem("sessionID") 
    userSessionURL = USER_SESSION_URL + sessionID;
    console.log(sessionID);
    return (
          <React.Fragment>
              <div className="footer-QR-wrapper">
                <div className="export-QR-wrapper-img"> 
                    <img src={`https://api.qrserver.com/v1/create-qr-code/?size=140x140&data=${userSessionURL}%3Futm_source%3Doctopus%26utm_medium%3Dtablet%26utm_campaign%3DQR_code`} width="130px" height="130px" className="use-qr"  alt="logo"/>  
                    {/* <span className="qr-text-line1">Scan Barcode</span>
                    <span className="qr-text-line2">to get your list</span> */}
                </div>
                <div className="export-QR-wrapper-text">
                    <p className="export-QR-line1">Scan the QR Code</p>
                    <p className="export-QR-line2">To import your Wishlist</p>
                    <p className="export-QR-line3">To your mobile device</p>
                </div>
                <div className="export-QR-wrapper-gif">
                  <img src={scan_qr_code} width="250px" height="200px" alt="gif"/> 
                </div>         
              </div>

                {/* <Grid container spacing={0} className="export-wl-wrapper" >
                    <Grid item className="use-mail-wrapper" xs={4}>
                        <DialogModal imgURL={mail_icon}
                                     type="email"
                                     name="mail"
                                     pattern="*"
                                     imgWidth="55px" 
                                     imgHeight="45px" 
                                     imgAlt="alt"
                                     btnText="Send" 
                                     title="mail"
                                     subtitle="wish list via e-mail"
                                     inputType="text"                                    
                                     classString="use-whatsapp"
                                     sessionID={sessionID} 
                                     submitHandler={submitEmailHandler} />
                        <p className="get-by">Get by e-mail</p>
                    </Grid>
                    <Grid item className="use-whatsapp-wrapper" xs={4}>
                        <DialogModal imgURL={wa_icon}
                                     type="tel"
                                     name="phone"
                                     pattern="^\+?[1-9]{1}[0-9]{3,14}$"
                                     imgWidth="50px" 
                                     imgHeight="50px" 
                                     imgAlt="alt"
                                     btnText="Send" 
                                     title="Whatsapp"
                                     subtitle="wish list via whatsapp"
                                     inputType="text"
                                     userSessionURL={`${userSessionURL}%3Futm_source%3Doctopus%26utm_medium%3Dtablet%26utm_campaign%3Dwhatsapp`}  
                                     sessionID={sessionID}                                  
                                     classString="use-whatsapp"
                                     submitHandler={submitWhatsappHandler} />
                        <p className="get-by">Get by What'sApp</p>
                    </Grid>
                    <Grid item className="use-link-wrapper" xs={4}>
                        <img src={link_icon} alt="link" width="50px" height="50px" />
                        <p className="get-by">Get by Link</p>
                    </Grid>
                </Grid> */}
          </React.Fragment>
           )
}

export default ExportWishList;
